import { get } from "../lib/http";

class SolarPanelsService {
  getDay(date) {
    return get(`/zonnepanelen/dag${date ? `/${date}` : ""}`);
  }

  getMonth(month) {
    return get(`/zonnepanelen/maand${month ? `/${month}` : ""}`);
  }

  getYear(year) {
    return get(`/zonnepanelen/jaar${year ? `/${year}` : ""}`);
  }

  getTotal() {
    return get("/zonnepanelen/totaal");
  }
}

export default SolarPanelsService;
