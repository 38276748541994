import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LogService from "../../services/LogService";
import { useParams, useNavigate } from "react-router-dom";
import LogSmartMeterForm from "../../components/Form/SmartMeterLogForm";

function LogSmartMeterEdit() {
  const { datetime } = useParams();
  const [log, setLog] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const logService = new LogService();
  let navigate = useNavigate();

  function handleSubmit(e) {
    e.preventDefault();
    setIsLoading(true);

    logService
      .editSmartMeterLog(log, datetime)
      .then(() => {
        setError(false);
        setIsLoading(false);
      })
      .catch((error) => {
        setError(error.response.data.error.message);
        setIsLoading(false);
      });
  }

  function loadData() {
    setIsLoading(true);

    logService
      .getSmartMeterLog(datetime)
      .then((response) => {
        setLog(response.data);
        setIsLoading(false);
      })
      .catch(() => {
        navigate(-1);
      });
  }

  useEffect(() => {
    loadData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="card mb-3">
      <div className="card-header">
        <FontAwesomeIcon icon="cog" /> Slimme meter - wijzigen
      </div>
      <div className={`card-body` + (isLoading ? " loading" : "")}>
        <h5 style={{ textAlign: "center" }}>Slimme meter - wijzigen</h5>
        <br />

        {log ? (
          <React.Fragment>
            {error && (
              <div className="alert alert-danger" role="alert">
                Fout opgetreden: "{error}"
              </div>
            )}

            {error === false && (
              <div className="alert alert-success" role="alert">
                Succesvol opgeslagen
              </div>
            )}

            <LogSmartMeterForm
              log={log}
              setLog={setLog}
              isLoading={isLoading}
              handleSubmit={handleSubmit}
            />
          </React.Fragment>
        ) : (
          "Laden.."
        )}
      </div>
    </div>
  );
}

export default LogSmartMeterEdit;
