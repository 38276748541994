import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import SmartMeterService from "../../services/SmartMeterService";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";
import "moment/locale/nl";
import SmartMeterElektricityStats from "../../components/Statistics/SmartMeterElektricityStats";
import ucfirst from "../../utilities/functions";
import SmartMeterGasStats from "../../components/Statistics/SmartMeterGasStats";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loader from "../../components/Loader/Loader";
import { useSelector } from "react-redux";

function SmartMeterMonth(props) {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);
  const { month } = useParams();

  function loadData() {
    setIsLoading(true);

    const smartMeterService = new SmartMeterService();

    smartMeterService.getMonth(month).then((response) => {
      setData(response.data);
      setIsLoading(false);
    });
  }

  function getOptions(type) {
    let point = {
      events: {
        click: (e) => {
          const date = data.month + "-" + e.point.category.replace(/^\D+/, "");

          navigate("/slimme-meter/dag/" + date);
        },
      },
    };
    let yAxis = [
      {
        title: {
          text: "kWh",
        },
      },
    ];
    let series = [];

    if (data.series.consumption.length > 0) {
      series.push({
        name: "Verbruik",
        color: "#e86710",
        data: data.series.consumption,
        point,
      });
    }
    if (data.series.supply.length > 0) {
      series.push({
        name: "Levering",
        color: "#99450c",
        data: data.series.supply,
        point,
      });
    }
    if (data.series.return.length > 0) {
      series.push({
        name: "Teruglevering",
        color: "#0a7d29",
        data: data.series.return,
        point,
      });
    }
    if (data.series.generation.length > 0) {
      series.push({
        name: "Opwekking",
        color: "#f4cb42",
        data: data.series.generation,
        point,
      });
    }

    if (type === "gas") {
      yAxis[0].title.text = "m3";
      series = [
        {
          name: "Levering",
          color: "#57430a",
          data: data.series.gas_supply,
          point,
        },
      ];
    }

    if (
      user.roles.includes("ROLE_TEMPERATURE") &&
      data.series.temperature?.length > 0
    ) {
      yAxis.push({
        title: {
          text: "Temperatuur",
        },
        opposite: true,
      });
      series.push({
        name: "Buiten temperatuur",
        color: "#a10a7b",
        data: data.series.temperature,
        type: "line",
        yAxis: 1,
        point,
      });
    }

    return {
      chart: {
        type: "column",
        zoomType: "x",
      },
      title: {
        text: type === "consumption" ? "Elektriciteit" : "Gas",
      },
      subtitle: {
        text: ucfirst(moment(data.month).format("MMMM YYYY")),
      },
      tooltip: {
        shared: true,
      },
      xAxis: {
        categories: data.days,
      },
      yAxis: yAxis,
      series: series,
      plotOptions: {
        area: {
          marker: {
            enabled: false,
          },
        },
        line: {
          marker: {
            enabled: false,
          },
        },
      },
      credits: {
        enabled: false,
      },
    };
  }

  useEffect(() => {
    loadData(month);
  }, [month]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="card mb-3">
      <div className="card-header">
        <FontAwesomeIcon icon="chart-bar" /> Maandverbruik
      </div>
      <div className={`card-body` + (data && isLoading ? " loading" : "")}>
        {data ? (
          <React.Fragment>
            <Link
              to={`/slimme-meter/maand/${data.prev_month}`}
              className="btn btn-outline-dark btn-sm"
            >
              « {ucfirst(moment(data.prev_month).format("MMM YYYY"))}
            </Link>

            {moment(data.month).isBefore(moment().format("YYYY-MM")) && (
              <Link
                to={`/slimme-meter/maand/${data.next_month}`}
                className="btn btn-outline-dark btn-sm"
                style={{ float: "right" }}
              >
                {ucfirst(moment(data.next_month).format("MMM YYYY"))} »
              </Link>
            )}

            <div style={{ marginTop: "50px" }}>
              <HighchartsReact
                highcharts={Highcharts}
                options={getOptions("consumption")}
              />

              <SmartMeterElektricityStats data={data} />
            </div>

            {data.series.gas_supply.length > 0 && (
              <React.Fragment>
                <hr style={{ marginTop: "50px" }} />
                <div style={{ marginTop: "50px" }}>
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={getOptions("gas")}
                  />

                  <SmartMeterGasStats data={data} />
                </div>
              </React.Fragment>
            )}
          </React.Fragment>
        ) : (
          <Loader />
        )}
      </div>
      {!isLoading && <div className="card-footer small text-muted">.</div>}
    </div>
  );
}

export default SmartMeterMonth;
