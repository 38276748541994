import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import TadoService from "../../services/TadoService";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";
import "moment/locale/nl";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loader from "../../components/Loader/Loader";

function TadoYear(props) {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const { year } = useParams();

  function loadData() {
    setIsLoading(true);

    const tadoService = new TadoService();

    tadoService.getYear(year).then((response) => {
      setData(response.data);
      setIsLoading(false);
    });
  }

  function getOptions(type) {
    let point = {
      events: {
        click: (e) => {
          let month = e.point.category;
          month = `${data.year}-${moment().month(month).format("MM")}`;

          navigate("/tado/maand/" + month);
        },
      },
    };

    let series = [];

    Object.keys(data.series).map((room) => {
      series.push({
        name: room,
        data: data.series[room][type],
        point,
      });

      return room;
    });

    return {
      chart: {
        type: "column",
        zoomType: "x",
      },
      title: {
        text:
          type === "temperature"
            ? "Temperatuur"
            : type === "humidity"
            ? "Luchtvochtigheid"
            : "Verwarmen",
      },
      subtitle: {
        text: data.year,
      },
      tooltip: {
        shared: true,
      },
      xAxis: {
        categories: [
          "Jan",
          "Feb",
          "Mrt",
          "Apr",
          "Mei",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Okt",
          "Nov",
          "Dec",
        ],
      },
      yAxis: {
        title: {
          text: type === "temperature" ? "°C" : "%",
        },
      },
      series: series,
      plotOptions: {
        area: {
          marker: {
            enabled: false,
          },
        },
        line: {
          marker: {
            enabled: false,
          },
        },
      },
      credits: {
        enabled: false,
      },
    };
  }

  useEffect(() => {
    loadData(year);
  }, [year]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="card mb-3">
      <div className="card-header">
        <FontAwesomeIcon icon="chart-bar" /> Tado - jaar
      </div>
      <div className={`card-body` + (data && isLoading ? " loading" : "")}>
        {data ? (
          <React.Fragment>
            <Link
              to={`/tado/jaar/${data.year - 1}`}
              className="btn btn-outline-dark btn-sm"
            >
              « {data.year - 1}
            </Link>

            {data.year + 1 <= parseInt(moment().format("YYYY")) && (
              <Link
                to={`/tado/jaar/${data.year + 1}`}
                className="btn btn-outline-dark btn-sm"
                style={{ float: "right" }}
              >
                {data.year + 1} »
              </Link>
            )}

            <div style={{ marginTop: "50px" }}>
              <HighchartsReact
                highcharts={Highcharts}
                options={getOptions("temperature")}
              />
            </div>

            <div style={{ marginTop: "50px" }}>
              <HighchartsReact
                highcharts={Highcharts}
                options={getOptions("humidity")}
              />
            </div>

            <div style={{ marginTop: "50px" }}>
              <HighchartsReact
                highcharts={Highcharts}
                options={getOptions("heating")}
              />
            </div>
          </React.Fragment>
        ) : (
          <Loader />
        )}
      </div>
      {!isLoading && <div className="card-footer small text-muted">.</div>}
    </div>
  );
}

export default TadoYear;
