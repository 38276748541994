import axios from "axios";
import { toast } from "react-toastify";
import AuthenticationService from "../services/AuthenticationService";
import {
  getCookieValue,
  setCookieValue,
  removeCookieValue,
} from "../lib/cookie";

const http = axios.create();
let isRefreshing = false;

http.interceptors.request.use((config) => {
  config.url = `${process.env.REACT_APP_BACKEND_URL}${config.url}`;
  config.params = config.params || {};

  if (getCookieValue("access_token") !== undefined) {
    config.headers.Authorization = "Bearer " + getCookieValue("access_token");
  }

  return config;
});

http.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const errorMessage =
      error.response?.data?.error?.message || "Unknown error in request";
    const originalRequest = error.config;

    if (
      error.response.status === 401 &&
      errorMessage.includes("Access token expired")
    ) {
      if (!isRefreshing && getCookieValue("refresh_token")) {
        return refresh(originalRequest);
      } else if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          retryRequest(originalRequest, resolve);
        });
      }

      logout();
    } else if (
      error.response.status === 500 &&
      document.getElementsByClassName("Toastify").length > 0
    ) {
      toast.error(errorMessage);
    }

    return Promise.reject(error);
  }
);

function refresh(originalRequest) {
  originalRequest.retry = true;
  isRefreshing = true;

  return new Promise(function (resolve, reject) {
    const authenticationService = new AuthenticationService();

    authenticationService
      .refreshToken(getCookieValue("refresh_token"))
      .then((response) => {
        const { access_token, refresh_token } = response.data;

        setCookieValue("access_token", access_token, { expires: 30 });
        setCookieValue("refresh_token", refresh_token, { expires: 30 });

        retryRequest(originalRequest, resolve);
      })
      .catch((err) => {
        logout();
      })
      .finally(() => {
        isRefreshing = false;
      });
  });
}

function retryRequest(originalRequest, resolve) {
  originalRequest.retry = true;
  originalRequest.url = originalRequest.url.replace(
    process.env.REACT_APP_BACKEND_URL,
    ""
  );
  originalRequest.headers.Authorization =
    "Bearer " + getCookieValue("access_token");

  resolve(http(originalRequest));
}

function logout() {
  removeCookieValue("access_token");
  removeCookieValue("refresh_token");
  window.location = "/";
}

export function get(url, params = {}) {
  return http.get(url, params);
}

export function post(url, data, params = {}) {
  return http.post(url, data, params);
}

export function put(url, data, params = {}) {
  return http.put(url, data, params);
}

export function del(url, data, params = {}) {
  params.data = data;
  return http.delete(url, params);
}

export default http;
