import React, { useEffect, useState } from "react";
import ManagementService from "../../services/ManagementService";
import { Link, useParams } from "react-router-dom";
import moment from "moment";
import "moment/locale/nl";
import ucfirst from "../../utilities/functions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loader from "../../components/Loader/Loader";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import Button from "react-bootstrap/Button";

function ManagePricesHour() {
  const [data, setData] = useState(null);
  const [cheapestHours, setCheapestHours] = useState(
    localStorage.getItem("cheapest_hours") !== null
      ? localStorage.getItem("cheapest_hours")
      : 3
  );
  const [isLoading, setIsLoading] = useState(true);
  const [activeGraph, setIsActiveGraph] = useState("day");
  const { day } = useParams();
  const managementService = new ManagementService();

  function loadData() {
    if (cheapestHours === "") {
      return;
    }

    setIsLoading(true);

    managementService.getPricesHour(day, cheapestHours).then((response) => {
      setData(response.data);
      setIsLoading(false);
    });
  }

  function getOptions(period) {
    let title = "Uurprijzen";
    let subTitle = ucfirst(moment(data.date).format("dddd DD MMMM YYYY"));
    let xAxis = {
      type: "datetime",
      tickInterval: 3600 * 1000,
      min: data.start_time,
      max: data.end_time,
      plotLines: [
        {
          color: "red",
          width: 2,
          value: moment().startOf("hour"),
          dashStyle: "ShortDot",
        },
      ],
    };
    let series = [
      {
        name: "Prijs incl. btw",
        color: "#2ccc00",
        data: data.series.day.price,
        zones: [
          {
            value: data.average_kwh.day - data.average_kwh.day * 0.1,
            color: "#2ccc00",
          },
          {
            value: data.average_kwh.day + data.average_kwh.day * 0.1,
            color: "grey",
          },
          {
            color: "#ff9900",
          },
        ],
      },
      {
        type: "line",
        name: "Gemiddelde",
        color: "black",
        dashStyle: "LongDash",
        data: data.series.day.avg,
        lineWidth: 1,
        cursor: "pointer",
        marker: {
          enabled: false,
        },
      },
    ];

    if (period === "month") {
      title = "Dagprijzen";
      subTitle = ucfirst(moment(data.date).format("MMMM YYYY"));
      xAxis = {
        categories: data.days,
      };
      series[0].data = data.series.month.price;
      series[0].zones[0].value =
        data.average_kwh.month - data.average_kwh.month * 0.1;
      series[0].zones[1].value =
        data.average_kwh.month + data.average_kwh.month * 0.1;
      series[1].data = data.series.month.avg;
    } else if (period === "year") {
      title = "Maandprijzen";
      subTitle = ucfirst(moment(data.date).format("YYYY"));
      xAxis = {
        categories: [
          "Jan",
          "Feb",
          "Mrt",
          "Apr",
          "Mei",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Okt",
          "Nov",
          "Dec",
        ],
      };
      series[0].data = data.series.year.price;
      series[0].zones[0].value =
        data.average_kwh.year - data.average_kwh.year * 0.1;
      series[0].zones[1].value =
        data.average_kwh.year + data.average_kwh.year * 0.1;
      series[1].data = data.series.year.avg;
    }

    return {
      chart: {
        type: "line",
        zoomType: "x",
      },
      title: {
        text: title,
      },
      subtitle: {
        text: subTitle,
      },
      tooltip: {
        shared: true,
      },
      xAxis: xAxis,
      yAxis: [
        {
          title: {
            text: "Euro",
          },
        },
      ],
      series: series,
      plotOptions: {
        series: {
          marker: {
            enabled: false,
          },
        },
      },
      credits: {
        enabled: false,
      },
    };
  }

  useEffect(() => {
    loadData();
  }, [day, cheapestHours]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="card mb-3">
      <div className="card-header">
        <FontAwesomeIcon icon="table" /> Prijzen per uur
      </div>
      <div className={`card-body` + (data && isLoading ? " loading" : "")}>
        {data ? (
          <React.Fragment>
            <Link
              to={`/beheer/prijzen/uur/${data.prev_day}`}
              className="btn btn-outline-dark btn-sm"
            >
              « {ucfirst(moment(data.prev_day).format("dd DD MMM YYYY"))}
            </Link>

            {moment(data.date).isSameOrBefore(
              moment().format("YYYY-MM-DD")
            ) && (
              <Link
                to={`/beheer/prijzen/uur/${data.next_day}`}
                className="btn btn-outline-dark btn-sm"
                style={{ float: "right" }}
              >
                {ucfirst(moment(data.next_day).format("dd DD MMM YYYY"))} »
              </Link>
            )}

            <h5 className="mt-4 mb-4" style={{ textAlign: "center" }}>
              {ucfirst(moment(data.date).format("dddd DD MMMM YYYY"))}
            </h5>

            <div className="mt-3">
              <Button
                className="btn btn-secondary btn-sm me-2"
                onClick={(e) => {
                  setIsActiveGraph("day");
                }}
              >
                Dag
              </Button>
              <Button
                className="btn btn-secondary btn-sm me-2"
                onClick={(e) => {
                  setIsActiveGraph("month");
                }}
              >
                Maand
              </Button>
              <Button
                className="btn btn-secondary btn-sm me-2"
                onClick={(e) => {
                  setIsActiveGraph("year");
                }}
              >
                Jaar
              </Button>
            </div>

            <div className="mt-1 mb-4">
              {activeGraph === "day" && (
                <HighchartsReact
                  highcharts={Highcharts}
                  options={getOptions("day")}
                />
              )}
              {activeGraph === "month" && (
                <HighchartsReact
                  highcharts={Highcharts}
                  options={getOptions("month")}
                />
              )}
              {activeGraph === "year" && (
                <HighchartsReact
                  highcharts={Highcharts}
                  options={getOptions("year")}
                />
              )}
            </div>

            <h4 className="mb-3">Goedkoopste uren</h4>

            <Form.Group as={Row} className="mb-3" controlId="supply_low">
              <Form.Label column sm="1">
                Aantal uren:
              </Form.Label>
              <Col sm="1">
                <Form.Control
                  type="number"
                  value={cheapestHours}
                  onChange={(e) => {
                    localStorage.setItem("cheapest_hours", e.target.value);
                    setCheapestHours(e.target.value);
                  }}
                  required
                />
              </Col>
            </Form.Group>

            <Table responsive>
              <thead>
                <tr style={{ fontWeight: "bold" }}>
                  <th scope="col" style={{ minWidth: "175px" }}>
                    Periode
                  </th>
                  <th scope="col" style={{ minWidth: "220px" }}>
                    Gem. kWh incl. btw ({data.btw_type}%)
                  </th>
                </tr>
              </thead>

              <tbody>
                {data.cheapest_hours.map((hour, index) => {
                  return (
                    <tr
                      key={index}
                      style={{ color: hour.past ? "#8080809c" : "" }}
                    >
                      <td>
                        {hour.start_hour} - {hour.end_hour} uur
                      </td>
                      <td>{hour.avg.toFixed(6)}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>

            <h4 className="mt-4 mb-3">Uurprijzen</h4>

            <Table responsive style={{ minWidth: "1400px" }}>
              <thead>
                <tr style={{ fontWeight: "bold" }}>
                  <th scope="col" style={{ minWidth: "175px" }}>
                    Datum & tijd
                  </th>
                  <th scope="col" style={{ minWidth: "200px" }}>
                    kWh incl. btw ({data.btw_type}%)
                  </th>
                  <th scope="col" style={{ minWidth: "200px" }}>
                    kWh salderen
                  </th>
                  <th scope="col" style={{ minWidth: "200px" }}>
                    kWh excl. btw
                  </th>
                  <th scope="col" style={{ minWidth: "200px" }}>
                    kWh inkoop
                  </th>
                  <th scope="col" style={{ minWidth: "200px" }}>
                    Energiebelasting
                  </th>
                  <th scope="col" style={{ minWidth: "200px" }}>
                    Handelskosten
                  </th>
                  <th scope="col"></th>
                </tr>
              </thead>

              <tbody>
                {data.prices.map((price, index) => {
                  return (
                    <tr key={index}>
                      <td>{price.datetime}</td>
                      {data.btw_type === 9 && <td>{price.kwh_btw_9}</td>}
                      {data.btw_type === 21 && <td>{price.kwh_btw_21}</td>}
                      <td>{price.kwh_netting.toFixed(6)}</td>
                      <td>{price.kwh_ex_btw}</td>
                      <td>{price.kwh}</td>
                      <td>{price.tax}</td>
                      <td>{price.handling_fee}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </React.Fragment>
        ) : (
          <Loader />
        )}
      </div>
    </div>
  );
}

export default ManagePricesHour;
