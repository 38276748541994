import { get } from "../lib/http";

class SmartMeterService {
  getDay(date) {
    return get(`/slimme-meter/dag${date ? `/${date}` : ""}`);
  }

  getMonth(month) {
    return get(`/slimme-meter/maand${month ? `/${month}` : ""}`);
  }

  getYear(year) {
    return get(`/slimme-meter/jaar${year ? `/${year}` : ""}`);
  }

  getTotal() {
    return get("/slimme-meter/totaal");
  }
}

export default SmartMeterService;
