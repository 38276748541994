import React, { useEffect, useState } from "react";
import ManagementService from "../../services/ManagementService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loader from "../../components/Loader/Loader";
import LoadingButton from "../../components/Button/LoadingButton";
import Form from "react-bootstrap/Form";

function ManagePredictions() {
  const [isLoading, setIsLoading] = useState(true);
  const managementService = new ManagementService();
  const [predictions, setPredictions] = useState(null);
  const [error, setError] = useState(null);
  const months = [
    "Januari",
    "Februari",
    "Maart",
    "April",
    "Mei",
    "Juni",
    "Juli",
    "Augustus",
    "September",
    "Oktober",
    "November",
    "December",
  ];

  function loadData() {
    setIsLoading(true);

    managementService.getPredictions().then((response) => {
      const predictions = { months: {} };

      response.data.solar_panels.forEach((prediction) => {
        predictions.months[prediction.month] = prediction;
        predictions.start_date = prediction.start_date;
      });

      setPredictions(predictions);
      setIsLoading(false);
    });
  }

  function handleSubmit(e) {
    e.preventDefault();
    setIsLoading(true);

    managementService
      .savePredictions({ solar_panels: predictions })
      .then(() => {
        setError(false);
        setIsLoading(false);
      })
      .catch((error) => {
        setError(error.response.data.error.message);
        setIsLoading(false);
      });
  }

  useEffect(() => {
    loadData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="card mb-3">
      <div className="card-header">
        <FontAwesomeIcon icon="cog" /> Verwachtingen
      </div>
      <div
        className={`card-body` + (predictions && isLoading ? " loading" : "")}
      >
        <h5 style={{ textAlign: "center" }}>Verwachtingen</h5>
        <br />

        <div style={{ overflowX: "auto" }}>
          {predictions ? (
            <React.Fragment>
              {error && (
                <div className="alert alert-danger" role="alert">
                  Fout opgetreden: "{error}"
                </div>
              )}

              {error === false && (
                <div className="alert alert-success" role="alert">
                  Succesvol opgeslagen
                </div>
              )}

              <form method="POST" onSubmit={handleSubmit}>
                <h5 className="h5 mb-3 fw-normal">Zonnepanelen (kWh)</h5>
                {months.map((month, index) => (
                  <React.Fragment key={index}>
                    <Form.Group className="mb-3" controlId={`month_${index}`}>
                      <Form.Label>{month}</Form.Label>
                      <Form.Control
                        type="number"
                        className="form-control"
                        required
                        value={predictions.months[index + 1].kwh}
                        onChange={(e) => {
                          const newPredictions = { ...predictions };
                          newPredictions.months[index + 1].kwh = parseInt(
                            e.target.value
                          );
                          setPredictions(newPredictions);
                        }}
                      />
                    </Form.Group>
                  </React.Fragment>
                ))}

                <Form.Group className="mb-3" controlId={`start_date`}>
                  <Form.Label>Startdatum</Form.Label>
                  <Form.Control
                    type="date"
                    className="form-control"
                    required
                    value={predictions.start_date}
                    onChange={(e) => {
                      const newPredictions = { ...predictions };
                      newPredictions.start_date = e.target.value;
                      setPredictions(newPredictions);
                    }}
                  />
                </Form.Group>

                <LoadingButton titleLoading="Opslaan.." isLoading={isLoading}>
                  Opslaan
                </LoadingButton>
              </form>
            </React.Fragment>
          ) : (
            <Loader />
          )}
        </div>
      </div>
    </div>
  );
}

export default ManagePredictions;
