import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LogService from "../../services/LogService";
import { useParams } from "react-router-dom";
import LogSmartMeterForm from "../../components/Form/SmartMeterLogForm";

function LogSmartMeterAdd() {
  const { day } = useParams();
  const [log, setLog] = useState({
    datetime: day + " ", // Add space because of datetime split in form
    rate: 1,
    consumption_current_kw: 0,
    return_current_kw: 0,
  });
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  function handleSubmit(e) {
    e.preventDefault();
    setIsLoading(true);

    const logService = new LogService();

    logService
      .postSmartMeterLog(log)
      .then(() => {
        setError(false);
        setIsLoading(false);
      })
      .catch((error) => {
        setError(error.response.data.error.message);
        setIsLoading(false);
      });
  }

  return (
    <div className="card mb-3">
      <div className="card-header">
        <FontAwesomeIcon icon="cog" /> Slimme meter - toevoegen
      </div>
      <div className={`card-body` + (isLoading ? " loading" : "")}>
        <h5 style={{ textAlign: "center" }}>Slimme meter - toevoegen</h5>
        <br />

        <React.Fragment>
          {error && (
            <div className="alert alert-danger" role="alert">
              Fout opgetreden: "{error}"
            </div>
          )}

          {error === false && (
            <div className="alert alert-success" role="alert">
              Succesvol opgeslagen
            </div>
          )}

          <LogSmartMeterForm
            log={log}
            setLog={setLog}
            isLoading={isLoading}
            handleSubmit={handleSubmit}
          />
        </React.Fragment>
      </div>
    </div>
  );
}

export default LogSmartMeterAdd;
