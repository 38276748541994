import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import TadoService from "../../services/TadoService";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";
import "moment/locale/nl";
import ucfirst from "../../utilities/functions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loader from "../../components/Loader/Loader";

function TadoDay(props) {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { day } = useParams();

  function loadData() {
    setIsLoading(true);

    const tadoService = new TadoService();

    tadoService.getDay(day).then((response) => {
      setData(response.data);
      setIsLoading(false);
    });
  }

  function getOptions(type) {
    let series = [];

    Object.keys(data.series).map((room) => {
      series.push({
        name: room,
        data: data.series[room][type],
      });

      return room;
    });

    return {
      chart: {
        type: "line",
        zoomType: "x",
      },
      title: {
        text:
          type === "temperature"
            ? "Temperatuur"
            : type === "humidity"
            ? "Luchtvochtigheid"
            : "Verwarmen",
      },
      subtitle: {
        text: ucfirst(moment(data.date).format("dddd DD MMMM YYYY")),
      },
      tooltip: {
        shared: true,
      },
      xAxis: {
        type: "datetime",
        tickInterval: 3600 * 1000,
        min: data.start_time,
        max: data.end_time,
      },
      yAxis: {
        title: {
          text: type === "temperature" ? "°C" : "%",
        },
      },
      series: series,
      plotOptions: {
        area: {
          marker: {
            enabled: false,
          },
        },
        line: {
          marker: {
            enabled: false,
          },
        },
      },
      credits: {
        enabled: false,
      },
    };
  }

  useEffect(() => {
    loadData();
  }, [day]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="card mb-3">
      <div className="card-header">
        <FontAwesomeIcon icon="chart-line" /> Tado - dag
      </div>
      <div className={`card-body` + (data && isLoading ? " loading" : "")}>
        {data ? (
          <React.Fragment>
            <Link
              to={`/tado/dag/${data.prev_day}`}
              className="btn btn-outline-dark btn-sm"
            >
              « {ucfirst(moment(data.prev_day).format("dd DD MMMM YYYY"))}
            </Link>

            {moment(data.date).isBefore(moment().format("YYYY-MM-DD")) && (
              <Link
                to={`/tado/dag/${data.next_day}`}
                className="btn btn-outline-dark btn-sm"
                style={{ float: "right" }}
              >
                {ucfirst(moment(data.next_day).format("dd DD MMMM YYYY"))} »
              </Link>
            )}

            <div style={{ marginTop: "50px" }}>
              <HighchartsReact
                highcharts={Highcharts}
                options={getOptions("temperature")}
              />
            </div>

            <div style={{ marginTop: "50px" }}>
              <HighchartsReact
                highcharts={Highcharts}
                options={getOptions("humidity")}
              />
            </div>

            <div style={{ marginTop: "50px" }}>
              <HighchartsReact
                highcharts={Highcharts}
                options={getOptions("heating")}
              />
            </div>
          </React.Fragment>
        ) : (
          <Loader />
        )}
      </div>
      {!isLoading && (
        <div className="card-footer small text-muted">
          Geupdatet om:{" "}
          {data && data.series.Woonkamer?.temperature?.length > 0
            ? moment(
                data.series.Woonkamer.temperature[
                  data.series.Woonkamer.temperature.length - 1
                ][0]
              ).format("HH:mm") + " uur"
            : "-"}
        </div>
      )}
    </div>
  );
}

export default TadoDay;
