import React from "react";
import { useSelector } from "react-redux";

function SmartMeterGasStats({ data }) {
  const user = useSelector((state) => state.auth.user);

  return (
    <div style={{ overflowX: "auto" }}>
      <table className="graph-stats" style={{ width: "500px" }}>
        <tbody>
          <tr>
            <td style={{ minWidth: "158px" }}>Levering:</td>
            <td style={{ textAlign: "right" }}>
              {data.totals.gas_supply.toFixed(2)} m3
            </td>
            <td style={{ textAlign: "right" }}>
              {data.totals.costs.gas_supply.toFixed(2)} €
            </td>
            <td style={{ textAlign: "left", paddingLeft: "10px" }}>
              (
              {data.totals.gas_supply > 0
                ? (
                    data.totals.costs.gas_supply / data.totals.gas_supply
                  ).toFixed(2)
                : 0}{" "}
              / m3)
            </td>
          </tr>
          {user.roles.includes("ROLE_TEMPERATURE") && (
            <React.Fragment>
              <tr>
                <td>Min temp:</td>
                <td style={{ textAlign: "right" }}>{data.temp_min} °C</td>
                <td />
                <td />
              </tr>
              <tr>
                <td>Max temp:</td>
                <td style={{ textAlign: "right" }}>{data.temp_max} °C</td>
                <td />
                <td />
              </tr>
            </React.Fragment>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default SmartMeterGasStats;
