import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import SolarPanelsService from "../../services/SolarPanelsService";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";
import "moment/locale/nl";
import ucfirst from "../../utilities/functions";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loader from "../../components/Loader/Loader";

function SolarPanelsMonth(props) {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const { month } = useParams();

  function loadData() {
    setIsLoading(true);

    const solarPanelsService = new SolarPanelsService();

    solarPanelsService.getMonth(month).then((response) => {
      setData(response.data);
      setIsLoading(false);
    });
  }

  function getOptions() {
    return {
      chart: {
        type: "column",
      },
      title: {
        text: "Opwekking",
      },
      subtitle: {
        text: ucfirst(moment(data.month).format("MMMM YYYY")),
      },
      tooltip: {
        shared: true,
      },
      xAxis: {
        categories: data.days,
      },
      yAxis: {
        min: 0,
        title: {
          text: "kWh",
        },
      },
      series: [
        {
          type: "column",
          name: "kWh",
          color: "#f4cb42",
          data: data.series.kwh,
          cursor: "pointer",
          point: {
            events: {
              click: (e) => {
                const date =
                  data.month + "-" + e.point.category.replace(/^\D+/, "");

                navigate({
                  pathname: "/zonnepanelen/dag/" + date,
                  state: { date: date },
                });
              },
            },
          },
        },
        {
          type: "line",
          name: "Verwachting",
          color: "#56844b",
          data: data.series.prediction,
          cursor: "pointer",
          marker: {
            enabled: false,
          },
        },
      ],
      credits: {
        enabled: false,
      },
    };
  }

  useEffect(() => {
    loadData(month);
  }, [month]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="card mb-3">
      <div className="card-header">
        <FontAwesomeIcon icon="chart-bar" /> Maandopbrengst
      </div>
      <div className={`card-body` + (data && isLoading ? " loading" : "")}>
        {data ? (
          <React.Fragment>
            <Link
              to={`/zonnepanelen/maand/${data.prev_month}`}
              className="btn btn-outline-dark btn-sm"
            >
              « {ucfirst(moment(data.prev_month).format("MMM YYYY"))}
            </Link>

            {moment(data.month).isBefore(moment().format("YYYY-MM")) && (
              <Link
                to={`/zonnepanelen/maand/${data.next_month}`}
                className="btn btn-outline-dark btn-sm"
                style={{ float: "right" }}
              >
                {ucfirst(moment(data.next_month).format("MMM YYYY"))} »
              </Link>
            )}

            <div style={{ marginTop: "50px" }}>
              <HighchartsReact highcharts={Highcharts} options={getOptions()} />

              <div style={{ overflowX: "auto" }}>
                <table className="graph-stats" style={{ width: "525px" }}>
                  <tbody>
                    <tr>
                      <td>Maand kWh:</td>
                      <td>{data.month_total.toFixed(2)}</td>
                    </tr>
                    <tr>
                      <td>Prognose:</td>
                      <td>
                        {data.prognosis.toFixed(2)} % / {data.prediction?.kwh}{" "}
                        kWh
                      </td>
                    </tr>
                    <tr>
                      <td>Werkelijk:</td>
                      <td>{data.actual.toFixed(2)} %</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </React.Fragment>
        ) : (
          <Loader />
        )}
      </div>
      {!isLoading && <div className="card-footer small text-muted">.</div>}
    </div>
  );
}

export default SolarPanelsMonth;
