import React, { useEffect, useRef, useState } from "react";
import RealtimeService from "../../services/RealtimeService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loader from "../../components/Loader/Loader";

function Realtime() {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const pageLeave = useRef(false);
  let interval;

  function loadData(init) {
    setIsLoading(init);

    if (pageLeave.current) {
      clearInterval(interval);
      return;
    }

    const realtimeService = new RealtimeService();

    realtimeService.getRealtime().then((response) => {
      setData(response.data);
      setIsLoading(false);

      if (!interval) {
        interval = setInterval(loadData, 5000);
      }
    });
  }

  useEffect(() => {
    loadData(true);

    return () => {
      pageLeave.current = true;
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="card mb-3">
      <div className="card-header">
        <FontAwesomeIcon icon="tachometer-alt" /> Actueel
      </div>
      <div className={`card-body` + (data && isLoading ? " loading" : "")}>
        {data ? (
          <React.Fragment>
            <h5 style={{ textAlign: "center" }}>Actueel</h5>
            <br />

            <div className="row">
              <div className="col-sm">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">Verbruik</h5>
                    <p className="card-text">
                      {(data.smart_meter.consumption_current_kw * 1000).toFixed(
                        0
                      )}{" "}
                      watt
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">Teruglevering</h5>
                    <p className="card-text">
                      {(data.smart_meter.return_current_kw * 1000).toFixed(0)}{" "}
                      watt
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">Opwekking</h5>
                    <p className="card-text">
                      {data.solar_panels.actual || 0} watt
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        ) : (
          <Loader />
        )}
      </div>
    </div>
  );
}

export default Realtime;
