import React from "react";
import moment from "moment";
import NavItem from "./NavItem";
import NavItemGroup from "./NavItemGroup";
import { useSelector, useDispatch } from "react-redux";
import ucfirst from "../../utilities/functions";
import { Link } from "react-router-dom";

function NavSideBar() {
  const dispatch = useDispatch();
  const sidebarCollapsed = useSelector((state) => state.nav.sidebarCollapsed);
  const user = useSelector((state) => state.auth.user);

  let logRoutes = [
    {
      name: "Slimme meter",
      prefix: "/log/slimme-meter/",
      to: `/log/slimme-meter/${moment().format("YYYY-MM-DD")}`,
    },
    {
      name: "Zonnepanelen",
      prefix: "/log/zonnepanelen/",
      to: `/log/zonnepanelen/${moment().format("YYYY-MM-DD")}`,
    },
  ];

  if (user.roles.includes("ROLE_TEMPERATURE")) {
    logRoutes.push({
      name: "Temperatuur",
      prefix: "/log/temperatuur/",
      to: `/log/temperatuur/${moment().format("YYYY-MM-DD")}`,
    });
  }

  if (user.roles.includes("ROLE_TADO")) {
    logRoutes.push({
      name: "Tado",
      prefix: "/log/tado/",
      to: `/log/tado/woonkamer/${moment().format("YYYY-MM-DD")}`,
    });
  }

  if (user.roles.includes("ROLE_WATER_SOFTENER")) {
    logRoutes.push({
      name: "Waterontharder",
      prefix: "/log/waterontharder/",
      to: `/log/waterontharder/${moment().format("YYYY-MM-DD")}`,
    });
  }

  let managementRoutes = [
    {
      name: "Prijzen maand",
      prefix: "/beheer/prijzen/maand",
      to: "/beheer/prijzen/maand",
    },
  ];

  if (user.roles.includes("ROLE_GAS")) {
    managementRoutes.push({
      name: "Prijzen dag",
      prefix: "/beheer/prijzen/dag",
      to: `/beheer/prijzen/dag/${moment().format("YYYY-MM")}`,
    });
  }

  managementRoutes = managementRoutes.concat([
    {
      name: "Prijzen uur",
      prefix: "/beheer/prijzen/uur/",
      to: `/beheer/prijzen/uur/${moment().format("YYYY-MM-DD")}`,
    },
    {
      name: "Termijnbedrag",
      prefix: "/beheer/termijnbedrag",
      to: "/beheer/termijnbedrag",
    },
    {
      name: "Verwachtingen",
      prefix: "/beheer/verwachtingen",
      to: "/beheer/verwachtingen",
    },
  ]);

  function handleSidebar() {
    dispatch({
      type: "TOGGLE_SIDEBAR",
    });
  }

  return (
    <nav
      className="navbar navbar-expand-lg navbar-dark bg-dark fixed-top"
      id="mainNav"
    >
      <Link className="navbar-brand" to="/">
        Energie
      </Link>

      <button
        className="navbar-toggler navbar-toggler-right collapsed"
        type="button"
        onClick={handleSidebar}
      >
        <span className="navbar-toggler-icon"></span>
      </button>

      <div
        className={`collapse navbar-collapse${sidebarCollapsed ? "" : " show"}`}
      >
        <ul className="navbar-nav navbar-sidenav">
          <NavItemGroup
            name="Slimme meter"
            icon="chart-line"
            routes={[
              {
                name: "Dag",
                prefix: "/slimme-meter/dag/",
                to: `/slimme-meter/dag/${moment().format("YYYY-MM-DD")}`,
              },
              {
                name: "Maand",
                prefix: "/slimme-meter/maand/",
                to: `/slimme-meter/maand/${moment().format("YYYY-MM")}`,
              },
              {
                name: "Jaar",
                prefix: "/slimme-meter/jaar/",
                to: `/slimme-meter/jaar/${moment().format("YYYY")}`,
              },
              {
                name: "Totaal",
                prefix: "/slimme-meter/totaal",
                to: `/slimme-meter/totaal`,
              },
            ]}
          />
          <NavItemGroup
            name="Zonnepanelen"
            icon="chart-area"
            routes={[
              {
                name: "Dag",
                prefix: "/zonnepanelen/dag/",
                to: `/zonnepanelen/dag/${moment().format("YYYY-MM-DD")}`,
              },
              {
                name: "Maand",
                prefix: "/zonnepanelen/maand/",
                to: `/zonnepanelen/maand/${moment().format("YYYY-MM")}`,
              },
              {
                name: "Jaar",
                prefix: "/zonnepanelen/jaar/",
                to: `/zonnepanelen/jaar/${moment().format("YYYY")}`,
              },
              {
                name: "Totaal",
                prefix: "/zonnepanelen/totaal",
                to: "/zonnepanelen/totaal",
              },
            ]}
          />
          {user.roles.includes("ROLE_TADO") && (
            <NavItemGroup
              name="Tado"
              icon="chart-line"
              routes={[
                {
                  name: "Dag",
                  prefix: "/tado/dag/",
                  to: `/tado/dag/${moment().format("YYYY-MM-DD")}`,
                },
                {
                  name: "Maand",
                  prefix: "/tado/maand/",
                  to: `/tado/maand/${moment().format("YYYY-MM")}`,
                },
                {
                  name: "Jaar",
                  prefix: "/tado/jaar/",
                  to: `/tado/jaar/${moment().format("YYYY")}`,
                },
                {
                  name: "Totaal",
                  prefix: "/tado/totaal",
                  to: "/tado/totaal",
                },
              ]}
            />
          )}
          {user.roles.includes("ROLE_WATER_SOFTENER") && (
            <NavItemGroup
              name="Waterontharder"
              icon="chart-line"
              routes={[
                {
                  name: "Dag",
                  prefix: "/waterontharder/dag/",
                  to: `/waterontharder/dag/${moment().format("YYYY-MM-DD")}`,
                },
                {
                  name: "Maand",
                  prefix: "/waterontharder/maand/",
                  to: `/waterontharder/maand/${moment().format("YYYY-MM")}`,
                },
                {
                  name: "Jaar",
                  prefix: "/waterontharder/jaar/",
                  to: `/waterontharder/jaar/${moment().format("YYYY")}`,
                },
                {
                  name: "Totaal",
                  prefix: "/waterontharder/totaal",
                  to: "/waterontharder/totaal",
                },
              ]}
            />
          )}
          <NavItemGroup name="Log" icon="table" routes={logRoutes} />
          <NavItemGroup name="Beheer" icon="cog" routes={managementRoutes} />

          {user.roles.includes("ROLE_CURRENT") && (
            <NavItem
              name="Actueel"
              icon="tachometer-alt"
              route={{ name: "Actueel", prefix: "/actueel", to: "/actueel" }}
            />
          )}
          <NavItemGroup
            name={`Account (${ucfirst(user.username)})`}
            icon="user"
            routes={[
              {
                name: "Profiel",
                prefix: "/account/profiel",
                to: "/account/profiel",
              },
              {
                name: "Uitloggen",
                prefix: "/account/uitloggen",
                to: "/account/uitloggen",
              },
            ]}
          />
        </ul>

        {/* <ul className="navbar-nav sidenav-toggler">
          <li className="nav-item">
            <a className="nav-link text-center" id="sidenavToggler">
              <i className="fa fa-fw fa-angle-left"></i>
            </a>
          </li>
        </ul> */}
      </div>
    </nav>
  );
}

export default NavSideBar;
