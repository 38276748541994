import React, { useEffect, useState } from "react";
import ManagementService from "../../services/ManagementService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loader from "../../components/Loader/Loader";
import Table from "react-bootstrap/Table";
import DropdownButton from "../../components/Button/DropdownButton";
import { useNavigate, Link } from "react-router-dom";
import { useSelector } from "react-redux";

function ManagePrices() {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const managementService = new ManagementService();
  const user = useSelector((state) => state.auth.user);
  let navigate = useNavigate();

  function editPrice(props) {
    navigate(`/beheer/prijzen/${props.date}`);
  }

  function deletePrice(props) {
    if (window.confirm("Weet je zeker dat je deze prijs wilt verwijderen?")) {
      managementService.deletePrice(props.date).then(() => {
        loadData();
      });
    }
  }

  function loadData() {
    setIsLoading(true);

    managementService.getPrices().then((response) => {
      setData(response.data);
      setIsLoading(false);
    });
  }

  useEffect(() => {
    loadData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="card mb-3">
      <div className="card-header">
        <FontAwesomeIcon icon="cog" /> Prijzen
      </div>
      <div className={`card-body` + (data && isLoading ? " loading" : "")}>
        <h5 style={{ textAlign: "center" }}>Prijzen</h5>
        <br />

        {data ? (
          <Table style={{ minWidth: "500px" }}>
            <thead>
              <tr style={{ fontWeight: "bold" }}>
                <th scope="col" style={{ minWidth: "175px" }}>
                  Startdatum
                </th>
                <th scope="col" style={{ minWidth: "200px" }}>
                  Levering normaal kWh
                </th>
                <th scope="col" style={{ minWidth: "200px" }}>
                  Levering dal kWh
                </th>
                <th scope="col" style={{ minWidth: "250px" }}>
                  Teruglevering normaal kWh
                </th>
                <th scope="col" style={{ minWidth: "200px" }}>
                  Teruglevering dal kWh
                </th>
                <th scope="col" style={{ minWidth: "100px" }}>
                  Gas m3
                </th>
                {user.roles.includes("ROLE_WATER_SOFTENER") && (
                  <th scope="col" style={{ minWidth: "100px" }}>
                    Water m3
                  </th>
                )}
                <th scope="col" style={{ minWidth: "100px" }}>
                  Termijnbedrag
                </th>
                <th scope="col"></th>
              </tr>
            </thead>

            <tbody>
              {data.prices.map((price, index) => (
                <tr className="align-middle" key={index}>
                  <td>{price.date}</td>
                  <td>{price.kwh_supply_high}</td>
                  <td>{price.kwh_supply_low}</td>
                  <td>{price.kwh_return_high}</td>
                  <td>{price.kwh_return_low}</td>
                  <td>{price.gas_m3}</td>
                  {user.roles.includes("ROLE_WATER_SOFTENER") && (
                    <td>{price.water_m3}</td>
                  )}
                  <td>{price.period_amount}</td>
                  <td>
                    <DropdownButton
                      size="sm"
                      title="Acties"
                      items={[
                        {
                          title: "Wijzigen",
                          onClick: editPrice,
                          onClickProps: { date: price.date },
                        },
                        {
                          title: "Verwijderen",
                          onClick: deletePrice,
                          onClickProps: { date: price.date },
                        },
                      ]}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <Loader />
        )}

        <Link
          to={`/beheer/prijzen/toevoegen`}
          className="btn btn-secondary mb-2"
        >
          Toevoegen
        </Link>
      </div>
    </div>
  );
}

export default ManagePrices;
