import { get } from "../lib/http";

class TadoService {
  getDay(date) {
    return get(`/tado/dag${date ? `/${date}` : ""}`);
  }

  getMonth(month) {
    return get(`/tado/maand${month ? `/${month}` : ""}`);
  }

  getYear(year) {
    return get(`/tado/jaar${year ? `/${year}` : ""}`);
  }

  getTotal() {
    return get("/tado/totaal");
  }
}

export default TadoService;
