import React, { useEffect, useState } from "react";
import LogService from "../../services/LogService";
import { Link, useParams } from "react-router-dom";
import moment from "moment";
import "moment/locale/nl";
import ucfirst from "../../utilities/functions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loader from "../../components/Loader/Loader";
import DropdownButton from "../../components/Button/DropdownButton";
import Table from "react-bootstrap/Table";

function LogSolarPanels() {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { day } = useParams();
  const logService = new LogService();

  function loadData() {
    setIsLoading(true);

    logService.getSolarPanelsLogs(day).then((response) => {
      setData(response.data);
      setIsLoading(false);
    });
  }

  function deleteLog(props) {
    if (
      window.confirm("Weet je zeker dat je deze log regel wilt verwijderen?")
    ) {
      logService
        .deleteSolarPanelsLog({
          datetime: props.datetime,
        })
        .then(() => {
          loadData();
        });
    }
  }

  useEffect(() => {
    loadData();
  }, [day]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="card mb-3">
      <div className="card-header">
        <FontAwesomeIcon icon="table" /> Zonnepanelen
      </div>
      <div className={`card-body` + (data && isLoading ? " loading" : "")}>
        {data ? (
          <React.Fragment>
            <Link
              to={`/log/zonnepanelen/${data.prev_day}`}
              className="btn btn-outline-dark btn-sm"
            >
              « {ucfirst(moment(data.prev_day).format("dd DD MMM YYYY"))}
            </Link>

            {moment(data.date).isBefore(moment().format("YYYY-MM-DD")) && (
              <Link
                to={`/log/zonnepanelen/${data.next_day}`}
                className="btn btn-outline-dark btn-sm"
                style={{ float: "right" }}
              >
                {ucfirst(moment(data.next_day).format("dd DD MMM YYYY"))} »
              </Link>
            )}

            <h5 className="mt-4 mb-4" style={{ textAlign: "center" }}>
              {ucfirst(moment(data.date).format("dddd DD MMMM YYYY"))}
            </h5>

            <Link
              to={`/log/zonnepanelen/${data.date}/toevoegen`}
              className="btn btn-secondary mb-2"
            >
              Toevoegen
            </Link>

            <Table responsive style={{ minWidth: "550px", minHeight: "200px" }}>
              <thead>
                <tr style={{ fontWeight: "bold" }}>
                  <th scope="col" style={{ minWidth: "175px" }}>
                    Datum & tijd
                  </th>
                  <th scope="col">Opbrengst (W)</th>
                  <th scope="col">Dag totaal (kWh)</th>
                  {data.data[0]?.status && <th scope="col">Status</th>}
                  <th scope="col"></th>
                </tr>
              </thead>

              <tbody>
                {data.data.map((log, index) => {
                  return (
                    <tr className="align-middle" key={index}>
                      <td>{log.datetime}</td>
                      <td>{log.actual}</td>
                      <td>{log.day}</td>
                      {log.status && <td>{log.status}</td>}
                      <td>
                        <DropdownButton
                          size="sm"
                          title="Acties"
                          items={[
                            {
                              title: "Verwijderen",
                              onClick: deleteLog,
                              onClickProps: { datetime: log.datetime },
                            },
                          ]}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </React.Fragment>
        ) : (
          <Loader />
        )}
      </div>
    </div>
  );
}

export default LogSolarPanels;
