import React, { useEffect, useState } from "react";
import ManagementService from "../../services/ManagementService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loader from "../../components/Loader/Loader";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import LoadingButton from "../../components/Button/LoadingButton";

function ManagePeriodAmount() {
  const [data, setData] = useState(null);
  const [estimation, setEstimation] = useState(
    JSON.parse(localStorage.getItem("estimation"))
  );
  const [isLoading, setIsLoading] = useState(true);
  const managementService = new ManagementService();

  function handleSubmit(e) {
    e.preventDefault();
    loadData();

    localStorage.setItem("estimation", JSON.stringify(estimation));
  }

  function loadData() {
    setIsLoading(true);

    managementService.getPeriodAmount(estimation).then((response) => {
      setData(response.data);

      if (estimation == null) {
        setEstimation({
          estimated_supply_kwh: response.data.usage_estimated.supply,
          estimated_return_kwh: response.data.usage_estimated.return,
          estimated_supply_gas: response.data.usage_estimated.gas_supply,
        });
      }

      setIsLoading(false);
    });
  }

  useEffect(() => {
    loadData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="card mb-3">
      <div className="card-header">
        <FontAwesomeIcon icon="cog" /> Termijnbedrag
      </div>
      <div className={`card-body` + (data && isLoading ? " loading" : "")}>
        <h5 style={{ textAlign: "center" }}>Termijnbedrag</h5>
        <br />

        {data && estimation ? (
          <div style={{ minWidth: "1000px" }}>
            <p>
              <b>Geschat verbruik:</b>
            </p>
            <Form style={{ width: "700px" }} onSubmit={handleSubmit}>
              <Row className="mb-3">
                <Form.Group as={Col} className="mb-3" controlId="date">
                  <Form.Label>Levering kWh</Form.Label>
                  <Form.Control
                    type="number"
                    value={estimation.estimated_supply_kwh}
                    onChange={(e) => {
                      setEstimation({
                        ...estimation,
                        estimated_supply_kwh: e.target.value,
                      });
                    }}
                    required
                  />
                </Form.Group>

                <Form.Group as={Col} className="mb-3" controlId="supply_high">
                  <Form.Label>Teruglevering kWh</Form.Label>
                  <Form.Control
                    type="number"
                    value={estimation.estimated_return_kwh}
                    onChange={(e) => {
                      setEstimation({
                        ...estimation,
                        estimated_return_kwh: e.target.value,
                      });
                    }}
                    required
                  />
                </Form.Group>

                <Form.Group as={Col} className="mb-3" controlId="supply_low">
                  <Form.Label>Gas m3</Form.Label>
                  <Form.Control
                    type="number"
                    value={estimation.estimated_supply_gas}
                    onChange={(e) => {
                      setEstimation({
                        ...estimation,
                        estimated_supply_gas: e.target.value,
                      });
                    }}
                    required
                  />
                </Form.Group>

                <Col className="mb-3">
                  <div style={{ position: "relative", top: "44%" }}>
                    <LoadingButton
                      titleLoading="Berekenen.."
                      isLoading={isLoading}
                    >
                      Bereken
                    </LoadingButton>
                  </div>
                </Col>
              </Row>
            </Form>

            <Table
              className="table-period-amount"
              style={{ width: "49%", float: "left" }}
            >
              <thead>
                <tr style={{ fontWeight: "bold" }}>
                  <th scope="col" style={{ width: "500px" }}>
                    Verbruikt:
                  </th>
                  <th scope="col" style={{ width: "100px" }}></th>
                  <th scope="col" style={{ width: "30px" }}></th>
                </tr>
              </thead>

              <tbody>
                <tr className="align-middle">
                  <td>Levering kWh</td>
                  <td>{data.usage.supply.toFixed(2)}</td>
                  <td></td>
                </tr>
                <tr className="align-middle">
                  <td>Teruglevering kWh</td>
                  <td>{data.usage.return.toFixed(2)}</td>
                  <td></td>
                </tr>
                <tr className="align-middle">
                  <td>Gas m3</td>
                  <td>{data.usage.gas_supply.toFixed(2)}</td>
                  <td></td>
                </tr>
              </tbody>
            </Table>

            <Table
              className="table-period-amount"
              style={{ width: "49%", float: "right" }}
            >
              <thead>
                <tr style={{ fontWeight: "bold" }}>
                  <th scope="col" style={{ width: "500px" }}>
                    Geschat verbruik:
                  </th>
                  <th scope="col" style={{ width: "100px" }}></th>
                  <th scope="col" style={{ width: "30px" }}></th>
                </tr>
              </thead>

              <tbody>
                <tr className="align-middle">
                  <td>Levering kWh</td>
                  <td>{data.usage_estimated.supply.toFixed(0)}</td>
                  <td></td>
                </tr>
                <tr className="align-middle">
                  <td>Teruglevering kWh</td>
                  <td>{data.usage_estimated.return.toFixed(0)}</td>
                  <td></td>
                </tr>
                <tr className="align-middle">
                  <td>Gas m3</td>
                  <td>{data.usage_estimated.gas_supply.toFixed(0)}</td>
                  <td></td>
                </tr>
              </tbody>
            </Table>

            <Table
              className="table-period-amount"
              style={{ width: "49%", float: "left" }}
            >
              <thead>
                <tr style={{ fontWeight: "bold" }}>
                  <th scope="col" style={{ width: "500px" }}>
                    Geschat resterend verbruik:
                  </th>
                  <th scope="col" style={{ width: "100px" }}></th>
                  <th scope="col" style={{ width: "30px" }}></th>
                </tr>
              </thead>

              <tbody>
                <tr className="align-middle">
                  <td>Levering kWh</td>
                  <td>{data.remaining.supply.toFixed(0)}</td>
                  <td></td>
                </tr>
                <tr className="align-middle">
                  <td>Teruglevering kWh</td>
                  <td>{data.remaining.return.toFixed(0)}</td>
                  <td></td>
                </tr>
                <tr className="align-middle">
                  <td>Gas m3</td>
                  <td>{data.remaining.gas_supply.toFixed(0)}</td>
                  <td></td>
                </tr>
              </tbody>
            </Table>

            <Table
              className="table-period-amount"
              style={{ width: "49%", float: "right" }}
            >
              <thead>
                <tr style={{ fontWeight: "bold" }}>
                  <th scope="col" style={{ width: "500px" }}>
                    Geschatte resterende kosten:
                  </th>
                  <th scope="col" style={{ width: "100px" }}></th>
                  <th scope="col" style={{ width: "30px" }}></th>
                </tr>
              </thead>

              <tbody>
                <tr className="align-middle">
                  <td>Levering kWh</td>
                  <td>€ {data.remaining.costs.supply.toFixed(2)}</td>
                  <td></td>
                </tr>
                <tr className="align-middle">
                  <td>Teruglevering kWh</td>
                  <td>€ {data.remaining.costs.return.toFixed(2)}</td>
                  <td>-</td>
                </tr>
                <tr className="align-middle">
                  <td>Gas m3</td>
                  <td>€ {data.remaining.costs.gas_supply.toFixed(2)}</td>
                  <td>+</td>
                </tr>
                <tr className="align-middle">
                  <td style={{ borderTop: "2px solid", fontWeight: "bold" }}>
                    Totaal
                  </td>
                  <td style={{ borderTop: "2px solid" }}>
                    € {data.remaining.estimations.costs.toFixed(2)}
                  </td>
                  <td></td>
                </tr>
              </tbody>
            </Table>

            <Table className="table-period-amount">
              <thead>
                <tr style={{ fontWeight: "bold" }}>
                  <th scope="col" style={{ minWidth: "500px" }}>
                    Kosten:
                  </th>
                  <th scope="col" style={{ width: "100px" }}></th>
                  <th scope="col" style={{ width: "30px" }}></th>
                </tr>
              </thead>

              <tbody>
                <tr className="align-middle">
                  <td>Verbruik tot nu toe</td>
                  <td>€ {data.usage.costs.total.toFixed(2)}</td>
                  <td></td>
                </tr>
                <tr className="align-middle">
                  <td>Geschatte resterende kosten</td>
                  <td>€ {data.remaining.estimations.costs.toFixed(2)}</td>
                  <td>+</td>
                </tr>
                <tr className="align-middle">
                  <td style={{ borderTop: "2px solid" }}>Totale kosten</td>
                  <td style={{ borderTop: "2px solid" }}>
                    €{" "}
                    {(
                      data.usage.costs.total + data.remaining.estimations.costs
                    ).toFixed(2)}
                  </td>
                  <td></td>
                </tr>

                <tr className="align-middle">
                  <td>Betaald</td>
                  <td>€ {data.usage.costs.total_payed.toFixed(2)}</td>
                  <td>-</td>
                </tr>

                <tr className="align-middle">
                  <td style={{ borderTop: "2px solid" }}>
                    Totaal nog te betalen
                  </td>
                  <td style={{ borderTop: "2px solid" }}>
                    € {data.remaining.costs.total.toFixed(2)}
                  </td>
                  <td></td>
                </tr>
                <tr className="align-middle">
                  <td>Resterende termijnen</td>
                  <td className="align-right">{data.remaining.months}</td>
                  <td>÷</td>
                </tr>
                <tr className="align-middle">
                  <td style={{ borderTop: "2px solid", fontWeight: "bold" }}>
                    Totaal per maand
                  </td>
                  <td style={{ borderTop: "2px solid", fontWeight: "bold" }}>
                    € {data.remaining.estimations.period_amount.toFixed(2)}
                  </td>
                  <td></td>
                </tr>
              </tbody>
            </Table>
          </div>
        ) : (
          <Loader />
        )}
      </div>
    </div>
  );
}

export default ManagePeriodAmount;
