import React, { useEffect, useState } from "react";
import TadoService from "../../services/TadoService";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loader from "../../components/Loader/Loader";

function TadoTotal() {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  function loadData() {
    setIsLoading(true);

    const tadoService = new TadoService();

    tadoService.getTotal().then((response) => {
      setData(response.data);
      setIsLoading(false);
    });
  }

  function getOptions(type) {
    let point = {
      events: {
        click: (e) => {
          navigate("/tado/jaar/" + e.point.category);
        },
      },
    };

    let series = [];

    Object.keys(data.series).map((room) => {
      series.push({
        name: room,
        data: data.series[room][type],
        point,
      });

      return room;
    });

    return {
      chart: {
        type: "column",
        zoomType: "x",
      },
      title: {
        text:
          type === "temperature"
            ? "Temperatuur"
            : type === "humidity"
            ? "Luchtvochtigheid"
            : "Verwarmen",
      },
      subtitle: {
        text: "Totaaloverzicht",
      },
      tooltip: {
        shared: true,
      },
      xAxis: {
        categories: data.years,
      },
      yAxis: {
        title: {
          text: type === "temperature" ? "°C" : "%",
        },
      },
      series: series,
      plotOptions: {
        area: {
          marker: {
            enabled: false,
          },
        },
        line: {
          marker: {
            enabled: false,
          },
        },
      },
      credits: {
        enabled: false,
      },
    };
  }

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div className="card mb-3">
      <div className="card-header">
        <FontAwesomeIcon icon="chart-bar" /> Tado - totaal
      </div>
      <div className={`card-body` + (data && isLoading ? " loading" : "")}>
        {data ? (
          <React.Fragment>
            <div style={{ marginTop: "50px" }}>
              <HighchartsReact
                highcharts={Highcharts}
                options={getOptions("temperature")}
              />
            </div>

            <div style={{ marginTop: "50px" }}>
              <HighchartsReact
                highcharts={Highcharts}
                options={getOptions("humidity")}
              />
            </div>

            <div style={{ marginTop: "50px" }}>
              <HighchartsReact
                highcharts={Highcharts}
                options={getOptions("heating")}
              />
            </div>
          </React.Fragment>
        ) : (
          <Loader />
        )}
      </div>
      {!isLoading && <div className="card-footer small text-muted">.</div>}
    </div>
  );
}

export default TadoTotal;
