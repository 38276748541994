import React from "react";
import { useNavigate } from "react-router-dom";
import LoadingButton from "../Button/LoadingButton";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

function LogSolarPanelsLogForm({ log, setLog, isLoading, handleSubmit }) {
  let navigate = useNavigate();

  return (
    <form method="POST" onSubmit={handleSubmit}>
      <Form.Group className="mb-3" controlId="date">
        <Form.Label>Datum</Form.Label>
        <Form.Control
          type="date"
          value={log.date}
          onChange={(e) => {
            setLog({
              ...log,
              date: e.target.value,
            });
          }}
        />
      </Form.Group>

      <Form.Group className="mb-3" controlId="time">
        <Form.Label>Tijd</Form.Label>
        <Form.Control
          type="time"
          className="form-control"
          required
          onChange={(e) => {
            setLog({
              ...log,
              datetime: log.date + " " + e.target.value,
            });
          }}
        />
      </Form.Group>

      <Form.Group className="mb-3" controlId="actual">
        <Form.Label>Opbrengst (W)</Form.Label>
        <Form.Control
          type="number"
          className="form-control"
          required
          onChange={(e) => {
            setLog({ ...log, actual: e.target.value });
          }}
        />
      </Form.Group>

      <Form.Group className="mb-3" controlId="day">
        <Form.Label>Dag totaal (kWh)</Form.Label>
        <Form.Control
          type="number"
          className="form-control"
          step="0.01"
          required
          onChange={(e) => {
            setLog({ ...log, day: e.target.value });
          }}
        />
      </Form.Group>

      <Button
        variant="outline-dark"
        className="me-3"
        onClick={(e) => {
          navigate(-1);
        }}
      >
        Terug
      </Button>

      <LoadingButton titleLoading="Opslaan.." isLoading={isLoading}>
        Opslaan
      </LoadingButton>
    </form>
  );
}

export default LogSolarPanelsLogForm;
