import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import WaterSoftenerService from "../../services/WaterSoftenerService";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";
import "moment/locale/nl";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loader from "../../components/Loader/Loader";

function WaterSoftenerYear(props) {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const { year } = useParams();

  function loadData() {
    setIsLoading(true);

    const waterSoftenerService = new WaterSoftenerService();

    waterSoftenerService.getYear(year).then((response) => {
      setData(response.data);
      setIsLoading(false);
    });
  }

  function getOptions() {
    return {
      chart: {
        type: "column",
        zoomType: "x",
      },
      title: {
        text: "Waterverbruik",
      },
      subtitle: {
        text: data.year,
      },
      xAxis: {
        categories: [
          "Jan",
          "Feb",
          "Mrt",
          "Apr",
          "Mei",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Okt",
          "Nov",
          "Dec",
        ],
      },
      yAxis: {
        title: {
          text: "Liters",
        },
      },
      series: [
        {
          name: "Liters",
          color: "#2069a6",
          data: data.series,
          point: {
            events: {
              click: (e) => {
                const month = `${data.year}-${(e.point.index + 1)
                  .toString()
                  .padStart(2, "0")}`;

                navigate("/waterontharder/maand/" + month);
              },
            },
          },
        },
      ],
      plotOptions: {
        area: {
          marker: {
            enabled: false,
          },
        },
        line: {
          marker: {
            enabled: false,
          },
        },
      },
      credits: {
        enabled: false,
      },
    };
  }

  useEffect(() => {
    loadData(year);
  }, [year]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="card mb-3">
      <div className="card-header">
        <FontAwesomeIcon icon="chart-bar" /> Waterverbruik - jaar
      </div>
      <div className={`card-body` + (data && isLoading ? " loading" : "")}>
        {data ? (
          <React.Fragment>
            <Link
              to={`/waterontharder/jaar/${data.year - 1}`}
              className="btn btn-outline-dark btn-sm"
            >
              « {data.year - 1}
            </Link>

            {data.year + 1 <= parseInt(moment().format("YYYY")) && (
              <Link
                to={`/waterontharder/jaar/${data.year + 1}`}
                className="btn btn-outline-dark btn-sm"
                style={{ float: "right" }}
              >
                {data.year + 1} »
              </Link>
            )}

            <div style={{ marginTop: "50px" }}>
              <HighchartsReact highcharts={Highcharts} options={getOptions()} />

              <div style={{ overflowX: "auto" }}>
                <table className="graph-stats" style={{ width: "525px" }}>
                  <tbody>
                    <tr>
                      <td>Verbruik:</td>
                      <td>{data.year_total} liter</td>
                    </tr>
                    <tr>
                      <td>Kosten:</td>
                      <td>
                        {data.prices.water_supply.toFixed(2)} € (
                        {data.prices.rates.water_m3 !== null
                          ? data.prices.rates.water_m3?.toFixed(2)
                          : 0}{" "}
                        / m3)
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </React.Fragment>
        ) : (
          <Loader />
        )}
      </div>
      {!isLoading && <div className="card-footer small text-muted">.</div>}
    </div>
  );
}

export default WaterSoftenerYear;
