import React, { useState, useEffect } from "react";
import ManagePriceForm from "../../components/Form/ManagePriceForm";
import ManagementService from "../../services/ManagementService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useParams } from "react-router-dom";

function ManagePricesEdit() {
  const params = useParams();
  const [price, setPrice] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const managementService = new ManagementService();
  const [error, setError] = useState(null);

  function handleSubmit(e) {
    e.preventDefault();
    setIsLoading(true);

    managementService
      .editPrice(price, params.date)
      .then(() => {
        setError(false);
        setIsLoading(false);
      })
      .catch((error) => {
        setError(error.response.data.error.message);
        setIsLoading(false);
      });
  }

  function loadData() {
    setIsLoading(true);

    managementService.getPrice(params.date).then((response) => {
      setPrice(response.data);
      setIsLoading(false);
    });
  }

  useEffect(() => {
    loadData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="card mb-3">
      <div className="card-header">
        <FontAwesomeIcon icon="cog" /> Prijzen - wijzigen
      </div>
      <div className={`card-body` + (isLoading ? " loading" : "")}>
        <h5 style={{ textAlign: "center" }}>Prijzen - wijzigen</h5>
        <br />

        {price ? (
          <React.Fragment>
            {error && (
              <div className="alert alert-danger" role="alert">
                Fout opgetreden: "{error}"
              </div>
            )}

            {error === false && (
              <div className="alert alert-success" role="alert">
                Succesvol opgeslagen
              </div>
            )}

            <ManagePriceForm
              price={price}
              setPrice={setPrice}
              isLoading={isLoading}
              handleSubmit={handleSubmit}
            />
          </React.Fragment>
        ) : (
          "Laden.."
        )}
      </div>
    </div>
  );
}

export default ManagePricesEdit;
