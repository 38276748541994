import { get } from "../lib/http";

class WaterSoftenerService {
  getDay(date) {
    return get(`/waterontharder/dag${date ? `/${date}` : ""}`);
  }

  getMonth(month) {
    return get(`/waterontharder/maand${month ? `/${month}` : ""}`);
  }

  getYear(year) {
    return get(`/waterontharder/jaar${year ? `/${year}` : ""}`);
  }

  getTotal() {
    return get("/waterontharder/totaal");
  }
}

export default WaterSoftenerService;
