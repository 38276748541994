import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import SmartMeterService from "../../services/SmartMeterService";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";
import "moment/locale/nl";
import SmartMeterElektricityStats from "../../components/Statistics/SmartMeterElektricityStats";
import ucfirst from "../../utilities/functions";
import SmartMeterGasStats from "../../components/Statistics/SmartMeterGasStats";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loader from "../../components/Loader/Loader";
import { useSelector } from "react-redux";
import Button from "react-bootstrap/Button";

function SmartMeterDay(props) {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const user = useSelector((state) => state.auth.user);
  const { day } = useParams();
  const [activeGraph, setIsActiveGraph] = useState("consumption_day");

  function loadData() {
    setIsLoading(true);

    const smartMeterService = new SmartMeterService();

    smartMeterService.getDay(day).then((response) => {
      setData(response.data);
      setIsLoading(false);
    });
  }

  function getOptions(type) {
    let chartType = type.startsWith("consumption") ? "line" : "area";
    let xAxis = {
      type: "datetime",
      tickInterval: 3600 * 1000,
      min: data.start_time,
      max: data.end_time,
    };
    let yAxis = [
      {
        title: {
          text: "Watt",
        },
      },
    ];
    let seriesData =
      type === "consumption_day" ? data.series.day : data.series.hour;
    let series = [];

    if (seriesData.consumption.length > 0) {
      series.push({
        name: "Verbruik",
        color: "#e86710",
        data: seriesData.consumption,
      });
    }
    if (seriesData.supply.length > 0) {
      series.push({
        name: "Levering",
        color: "#99450c",
        data: seriesData.supply,
      });
    }
    if (seriesData.return.length > 0) {
      series.push({
        name: "Teruglevering",
        color: "#0a7d29",
        data: seriesData.return,
      });
    }
    if (seriesData.generation.length > 0) {
      series.push({
        name: "Opwekking",
        color: "#f4cb42",
        data: seriesData.generation,
      });
    }

    if (type === "consumption_hour") {
      chartType = "column";
      xAxis = {
        categories: data.hours,
      };
      yAxis[0].title.text = "kWh";
    } else if (type === "gas") {
      yAxis[0].title.text = "Liters";
      series = [
        {
          name: "Levering",
          color: "#57430a",
          data: data.series.day.gas_supply,
        },
      ];
    }

    if (
      user.roles.includes("ROLE_TEMPERATURE") &&
      seriesData.temperature?.length > 0
    ) {
      yAxis.push({
        title: {
          text: "Temperatuur",
        },
        opposite: true,
      });
      series.push({
        name: "Buiten temperatuur",
        color: "#a10a7b",
        data:
          type === "gas" ? data.series.day.temperature : seriesData.temperature,
        type: "line",
        yAxis: 1,
        visible: type === "consumption_hour",
      });
    }

    return {
      chart: {
        type: chartType,
        zoomType: "x",
      },
      title: {
        text: type.startsWith("consumption") ? "Elektriciteit" : "Gas",
      },
      subtitle: {
        text: ucfirst(moment(data.date).format("dddd DD MMMM YYYY")),
      },
      tooltip: {
        shared: true,
      },
      xAxis: xAxis,
      yAxis: yAxis,
      series: series,
      plotOptions: {
        area: {
          marker: {
            enabled: false,
          },
        },
        line: {
          marker: {
            enabled: false,
          },
        },
      },
      credits: {
        enabled: false,
      },
    };
  }

  useEffect(() => {
    loadData();
  }, [day]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="card mb-3">
      <div className="card-header">
        <FontAwesomeIcon icon="chart-line" /> Dagverbruik
      </div>
      <div className={`card-body` + (data && isLoading ? " loading" : "")}>
        {data ? (
          <React.Fragment>
            <Link
              to={`/slimme-meter/dag/${data.prev_day}`}
              className="btn btn-outline-dark btn-sm"
            >
              « {ucfirst(moment(data.prev_day).format("dd DD MMMM YYYY"))}
            </Link>

            {moment(data.date).isBefore(moment().format("YYYY-MM-DD")) && (
              <Link
                to={`/slimme-meter/dag/${data.next_day}`}
                className="btn btn-outline-dark btn-sm"
                style={{ float: "right" }}
              >
                {ucfirst(moment(data.next_day).format("dd DD MMMM YYYY"))} »
              </Link>
            )}

            <div style={{ marginTop: "50px" }}>
              <div className="mt-3">
                <Button
                  className="btn btn-secondary btn-sm me-2"
                  onClick={(e) => {
                    setIsActiveGraph("consumption_day");
                  }}
                >
                  Minuut
                </Button>
                <Button
                  className="btn btn-secondary btn-sm me-2"
                  onClick={(e) => {
                    setIsActiveGraph("consumption_hour");
                  }}
                >
                  Uur
                </Button>
              </div>

              <div className="mt-1">
                {activeGraph === "consumption_day" && (
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={getOptions(activeGraph)}
                  />
                )}
                {activeGraph === "consumption_hour" && (
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={getOptions(activeGraph)}
                  />
                )}
              </div>

              <SmartMeterElektricityStats data={data} />
            </div>

            {data.series.day.gas_supply.length > 0 && (
              <React.Fragment>
                <hr style={{ marginTop: "50px" }} />
                <div style={{ marginTop: "50px" }}>
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={getOptions("gas")}
                  />

                  <SmartMeterGasStats data={data} />
                </div>
              </React.Fragment>
            )}
          </React.Fragment>
        ) : (
          <Loader />
        )}
      </div>
      {!isLoading && (
        <div className="card-footer small text-muted">
          Geupdatet om:{" "}
          {data && data.series.day.supply.length > 0
            ? moment(
                data.series.day.supply[data.series.day.supply.length - 1][0]
              ).format("HH:mm") + " uur"
            : "-"}
        </div>
      )}
    </div>
  );
}

export default SmartMeterDay;
