import React, { useState } from "react";
import ManagePriceForm from "../../components/Form/ManagePriceForm";
import ManagementService from "../../services/ManagementService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";

function ManagePricesAdd() {
  const user = useSelector((state) => state.auth.user);
  const [price, setPrice] = useState({
    date: "",
    user_id: user.id,
    kwh_supply_high: "",
    kwh_supply_low: "",
    kwh_return_high: "",
    kwh_return_low: "",
    gas_m3: "",
    water_m3: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const managementService = new ManagementService();
  const [error, setError] = useState(null);

  function handleSubmit(e) {
    e.preventDefault();
    setIsLoading(true);

    managementService
      .addPrice(price)
      .then(() => {
        setError(false);
        setIsLoading(false);
      })
      .catch((error) => {
        setError(error.response.data.error.message);
        setIsLoading(false);
      });
  }

  return (
    <div className="card mb-3">
      <div className="card-header">
        <FontAwesomeIcon icon="cog" /> Prijzen - toevoegen
      </div>
      <div className={`card-body` + (isLoading ? " loading" : "")}>
        <h5 style={{ textAlign: "center" }}>Prijzen - toevoegen</h5>
        <br />

        <div style={{ overflowX: "auto" }}>
          <React.Fragment>
            {error && (
              <div className="alert alert-danger" role="alert">
                Fout opgetreden: "{error}"
              </div>
            )}

            {error === false && (
              <div className="alert alert-success" role="alert">
                Succesvol opgeslagen
              </div>
            )}

            <ManagePriceForm
              price={price}
              setPrice={setPrice}
              isLoading={isLoading}
              handleSubmit={handleSubmit}
            />
          </React.Fragment>
        </div>
      </div>
    </div>
  );
}

export default ManagePricesAdd;
