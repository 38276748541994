import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
// import Breadcrumbs from '../components/Breadcrumbs/Breadcrumbs';
// import Footer from "../components/Footer/Footer";
import NavSideBar from "../components/Navigation/NavSidebar";
import SmartMeterDay from "./SmartMeter/day";
import SmartMeterMonth from "./SmartMeter/month";
import SolarPanelsDay from "./SolarPanels/day";
import Highcharts from "highcharts/highcharts";
import SmartMeterYear from "./SmartMeter/year";
import moment from "moment-timezone";
import "moment/locale/nl";
import SolarPanelsMonth from "./SolarPanels/month";
import SolarPanelsYear from "./SolarPanels/year";
import LogSmartMeter from "./Logs/SmartMeter";
import LogSolarPanels from "./Logs/SolarPanels";
import LogTemperature from "./Logs/Temperature";
import LogTado from "./Logs/Tado";
import Realtime from "./Realtime/Realtime";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import SignIn from "./SignIn/SignIn";
import { useSelector } from "react-redux";
import TadoDay from "./Tado/day";
import TadoMonth from "./Tado/month";
import TadoYear from "./Tado/year";
import TadoTotal from "./Tado/total";
import LogWaterSoftener from "./Logs/WaterSoftener";
import WaterSoftenerDay from "./WaterSoftener/day";
import WaterSoftenerMonth from "./WaterSoftener/month";
import WaterSoftenerYear from "./WaterSoftener/year";
import WaterSoftenerTotal from "./WaterSoftener/total";
import SmartMeterTotal from "./SmartMeter/total";
import SolarPanelsTotal from "./SolarPanels/total";
import Profile from "./Account/Profile";
import Logout from "./Account/Logout";
import LogSolarPanelsAdd from "./Logs/SolarPanelsAdd";
import ManagePrices from "./Management/Prices";
import ManagePricesHour from "./Management/PricesHour";
import ManagePricesAdd from "./Management/PricesAdd";
import ManagePricesEdit from "./Management/PricesEdit";
import ManagePeriodAmount from "./Management/PeriodAmount";
import ManagePredictions from "./Management/Predictions";
import LogSmartMeterAdd from "./Logs/SmartMeterAdd";
import LogSmartMeterEdit from "./Logs/SmartMeterEdit";
import ManagePricesDay from "./Management/PricesDay";

library.add(fas);
window.moment = moment;
window.Highcharts = Highcharts;

Highcharts.setOptions({
  time: {
    timezone: "Europe/Amsterdam",
  },
  lang: {
    months: [
      "Januari",
      "Februari",
      "Maart",
      "April",
      "Mei",
      "Juni",
      "Juli",
      "Augustus",
      "September",
      "Oktober",
      "November",
      "December",
    ],
    weekdays: [
      "Zondag",
      "Maandag",
      "Dinsdag",
      "Woensdag",
      "Donderdag",
      "Vrijdag",
      "Zaterdag",
    ],
    shortMonths: [
      "Jan",
      "Feb",
      "Mrt",
      "Apr",
      "Mei",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Okt",
      "Nov",
      "Dec",
    ],
    thousandsSep: ".",
    decimalPoint: ",",
  },
});

function App() {
  const user = useSelector((state) => state.auth.user);

  if (user === null) {
    return (
      <Routes>
        <Route path="/inloggen" element={<SignIn />} />
        <Route path="*" element={<Navigate to="/inloggen" />} />
      </Routes>
    );
  }

  return (
    <div className="fixed-nav bg-dark">
      <ToastContainer />
      <NavSideBar />

      <div className="content-wrapper vh-100">
        {/* <Breadcrumbs /> */}
        <Routes>
          <Route path="/slimme-meter/dag/:day" element={<SmartMeterDay />} />
          <Route
            path="/slimme-meter/maand/:month"
            element={<SmartMeterMonth />}
          />
          <Route path="/slimme-meter/jaar/:year" element={<SmartMeterYear />} />
          <Route path="/slimme-meter/totaal" element={<SmartMeterTotal />} />
          <Route path="/zonnepanelen/dag/:day" element={<SolarPanelsDay />} />
          <Route
            path="/zonnepanelen/maand/:month"
            element={<SolarPanelsMonth />}
          />
          <Route
            path="/zonnepanelen/jaar/:year"
            element={<SolarPanelsYear />}
          />
          <Route path="/zonnepanelen/totaal" element={<SolarPanelsTotal />} />
          <Route path="/tado/dag/:day" element={<TadoDay />} />
          <Route path="/tado/maand/:month" element={<TadoMonth />} />
          <Route path="/tado/jaar/:year" element={<TadoYear />} />
          <Route path="/tado/totaal" element={<TadoTotal />} />
          <Route
            path="/waterontharder/dag/:day"
            element={<WaterSoftenerDay />}
          />
          <Route
            path="/waterontharder/maand/:month"
            element={<WaterSoftenerMonth />}
          />
          <Route
            path="/waterontharder/jaar/:year"
            element={<WaterSoftenerYear />}
          />
          <Route
            path="/waterontharder/totaal"
            element={<WaterSoftenerTotal />}
          />
          <Route path="/log/slimme-meter/:day" element={<LogSmartMeter />} />
          <Route
            path="/log/slimme-meter/:day/toevoegen"
            element={<LogSmartMeterAdd />}
          />
          <Route
            path="/log/slimme-meter/:datetime/wijzigen"
            element={<LogSmartMeterEdit />}
          />
          <Route path="/log/zonnepanelen/:day" element={<LogSolarPanels />} />
          <Route
            path="/log/zonnepanelen/:day/toevoegen"
            element={<LogSolarPanelsAdd />}
          />
          <Route path="/log/temperatuur/:day" element={<LogTemperature />} />
          <Route path="/log/tado/:room/:day" element={<LogTado />} />
          <Route
            path="/log/waterontharder/:day"
            element={<LogWaterSoftener />}
          />
          <Route path="/beheer/prijzen/maand" element={<ManagePrices />} />
          <Route
            path="/beheer/termijnbedrag"
            element={<ManagePeriodAmount />}
          />
          <Route
            path="/beheer/prijzen/toevoegen"
            element={<ManagePricesAdd />}
          />
          <Route path="/beheer/prijzen/:date" element={<ManagePricesEdit />} />
          <Route
            path="/beheer/prijzen/dag/:month"
            element={<ManagePricesDay />}
          />
          <Route
            path="/beheer/prijzen/uur/:day"
            element={<ManagePricesHour />}
          />
          <Route path="/beheer/verwachtingen" element={<ManagePredictions />} />
          <Route path="/actueel" element={<Realtime />} />
          <Route path="/account/profiel" element={<Profile />} />
          <Route path="/account/uitloggen" element={<Logout />} />
          <Route
            path="*"
            element={
              <Navigate
                to={`/slimme-meter/dag/${moment().format("YYYY-MM-DD")}`}
              />
            }
          />
        </Routes>
      </div>

      {/* <Footer /> */}
    </div>
  );
}

export default App;
