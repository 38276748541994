import React from "react";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import LoadingButton from "../Button/LoadingButton";
import Form from "react-bootstrap/Form";
import { useSelector } from "react-redux";

function ManagePriceForm({ price, setPrice, isLoading, handleSubmit }) {
  let navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);

  return (
    <form method="POST" onSubmit={handleSubmit}>
      <Form.Group className="mb-3" controlId="date">
        <Form.Label>Startdatum</Form.Label>
        <Form.Control
          type="date"
          value={price.date}
          required
          onChange={(e) => {
            setPrice({ ...price, date: e.target.value });
          }}
        />
      </Form.Group>

      <Form.Group className="mb-3" controlId="supply_high">
        <Form.Label>Levering normaal kWh</Form.Label>
        <Form.Control
          type="number"
          step="any"
          placeholder="kWh prijs"
          value={price.kwh_supply_high}
          required
          onChange={(e) => {
            setPrice({ ...price, kwh_supply_high: e.target.value });
          }}
        />
      </Form.Group>

      <Form.Group className="mb-3" controlId="supply_low">
        <Form.Label>Levering dal kWh</Form.Label>
        <Form.Control
          type="number"
          step="any"
          placeholder="kWh prijs"
          value={price.kwh_supply_low}
          required
          onChange={(e) => {
            setPrice({ ...price, kwh_supply_low: e.target.value });
          }}
        />
      </Form.Group>

      <Form.Group className="mb-3" controlId="return_high">
        <Form.Label>Teruglevering normaal kWh</Form.Label>
        <Form.Control
          type="number"
          step="any"
          placeholder="kWh prijs"
          value={price.kwh_return_high}
          required
          onChange={(e) => {
            setPrice({ ...price, kwh_return_high: e.target.value });
          }}
        />
      </Form.Group>

      <Form.Group className="mb-3" controlId="return_low">
        <Form.Label>Teruglevering dal kWh</Form.Label>
        <Form.Control
          type="number"
          step="any"
          placeholder="kWh prijs"
          value={price.kwh_return_low}
          required
          onChange={(e) => {
            setPrice({ ...price, kwh_return_low: e.target.value });
          }}
        />
      </Form.Group>

      <Form.Group className="mb-3" controlId="gas">
        <Form.Label>Gas m3</Form.Label>
        <Form.Control
          type="number"
          step="any"
          placeholder="m3 prijs"
          value={price.gas_m3}
          onChange={(e) => {
            setPrice({ ...price, gas_m3: e.target.value });
          }}
        />
      </Form.Group>

      {user.roles.includes("ROLE_WATER_SOFTENER") && (
        <Form.Group className="mb-3" controlId="water_m3">
          <Form.Label>Water m3</Form.Label>
          <Form.Control
            type="number"
            step="any"
            placeholder="m3 prijs"
            defaultValue={price.water_m3}
            onChange={(e) => {
              setPrice({ ...price, water_m3: e.target.value });
            }}
          />
        </Form.Group>
      )}

      <Form.Group className="mb-3" controlId="period-amount">
        <Form.Label>Termijnbedrag</Form.Label>
        <Form.Control
          type="number"
          placeholder="Termijnbedrag"
          value={price.period_amount}
          onChange={(e) => {
            setPrice({ ...price, period_amount: e.target.value });
          }}
          required
        />
      </Form.Group>

      <Button
        variant="outline-dark"
        className="me-3"
        onClick={(e) => {
          navigate("/beheer/prijzen/maand");
        }}
      >
        Terug
      </Button>

      <LoadingButton titleLoading="Opslaan.." isLoading={isLoading}>
        Opslaan
      </LoadingButton>
    </form>
  );
}

export default ManagePriceForm;
