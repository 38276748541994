import React, { useEffect, useState } from "react";
import LogService from "../../services/LogService";
import { Link, useParams } from "react-router-dom";
import moment from "moment";
import "moment/locale/nl";
import ucfirst from "../../utilities/functions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loader from "../../components/Loader/Loader";
import Table from "react-bootstrap/Table";
import DropdownButton from "../../components/Button/DropdownButton";

function LogTado() {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { day, room } = useParams();
  const logService = new LogService();
  const rooms = [
    "Woonkamer",
    "Bijkeuken",
    "Gang",
    "Kantoor",
    "Badkamer",
    "Slaapkamer achter",
    "Slaapkamer voor",
  ];

  function loadData() {
    setIsLoading(true);

    logService.getTadoLogs(day, room).then((response) => {
      setData(response.data);
      setIsLoading(false);
    });
  }

  function deleteLog(props) {
    if (
      window.confirm("Weet je zeker dat je deze log regel wilt verwijderen?")
    ) {
      logService.deleteTadoLog(props).then(() => {
        loadData();
      });
    }
  }

  useEffect(() => {
    loadData();
  }, [day, room]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="card mb-3">
      <div className="card-header">
        <FontAwesomeIcon icon="table" /> Temperatuur
      </div>
      <div className={`card-body` + (data && isLoading ? " loading" : "")}>
        {data ? (
          <React.Fragment>
            <Link
              to={`/log/tado/${room}/${data.prev_day}`}
              className="btn btn-outline-dark btn-sm"
            >
              « {ucfirst(moment(data.prev_day).format("dd DD MMM YYYY"))}
            </Link>

            {moment(data.date).isBefore(moment().format("YYYY-MM-DD")) && (
              <Link
                to={`/log/tado/${room}/${data.next_day}`}
                className="btn btn-outline-dark btn-sm"
                style={{ float: "right" }}
              >
                {ucfirst(moment(data.next_day).format("dd DD MMM YYYY"))} »
              </Link>
            )}

            <h5 className="mt-4 mb-4" style={{ textAlign: "center" }}>
              {ucfirst(moment(data.date).format("dddd DD MMMM YYYY"))}
            </h5>

            <div className="mb-2">
              {rooms.map((room, key) => {
                return (
                  <Link
                    key={key}
                    to={`/log/tado/${room}/${day}`}
                    className="btn btn-secondary btn-sm me-2 mb-2"
                  >
                    {room}
                  </Link>
                );
              })}
            </div>

            <h5 className="mb-3">{ucfirst(room)}</h5>

            <Table responsive style={{ minWidth: "1000px" }}>
              <thead>
                <tr style={{ fontWeight: "bold" }}>
                  <th scope="col" style={{ minWidth: "175px" }}>
                    Datum & tijd
                  </th>
                  <th scope="col">Kamer</th>
                  <th scope="col">Temperatuur (°C)</th>
                  <th scope="col">Luchtvochtigheid (%)</th>
                  <th scope="col">Verwarmen (%)</th>
                  <th scope="col"></th>
                </tr>
              </thead>

              <tbody>
                {data.data.map((log, index) => {
                  return (
                    <tr key={index}>
                      <td>{log.datetime}</td>
                      <td>{log.room}</td>
                      <td>{log.temperature}</td>
                      <td>{log.humidity}</td>
                      <td>{log.heating}</td>
                      <td>
                        <DropdownButton
                          size="sm"
                          title="Acties"
                          items={[
                            {
                              title: "Verwijderen",
                              onClick: deleteLog,
                              onClickProps: {
                                datetime: log.datetime,
                                room: log.room,
                              },
                            },
                          ]}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </React.Fragment>
        ) : (
          <Loader />
        )}
      </div>
    </div>
  );
}

export default LogTado;
