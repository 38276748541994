import React, { useEffect, useState } from "react";
import WaterSoftenerService from "../../services/WaterSoftenerService";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loader from "../../components/Loader/Loader";

function WaterSoftenerTotal() {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  function loadData() {
    setIsLoading(true);

    const waterSoftenerService = new WaterSoftenerService();

    waterSoftenerService.getTotal().then((response) => {
      setData(response.data);
      setIsLoading(false);
    });
  }

  function getOptions() {
    return {
      chart: {
        type: "column",
        zoomType: "x",
      },
      title: {
        text: "Waterverbruik",
      },
      subtitle: {
        text: "Totaaloverzicht",
      },
      xAxis: {
        categories: data.years,
      },
      yAxis: {
        title: {
          text: "Liters",
        },
      },
      series: [
        {
          name: "Liters",
          color: "#2069a6",
          data: data.series,
          point: {
            events: {
              click: (e) => {
                navigate("/waterontharder/jaar/" + e.point.category);
              },
            },
          },
        },
      ],
      plotOptions: {
        area: {
          marker: {
            enabled: false,
          },
        },
        line: {
          marker: {
            enabled: false,
          },
        },
      },
      credits: {
        enabled: false,
      },
    };
  }

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div className="card mb-3">
      <div className="card-header">
        <FontAwesomeIcon icon="chart-bar" /> Waterverbruik - totaal
      </div>
      <div className={`card-body` + (data && isLoading ? " loading" : "")}>
        {data ? (
          <React.Fragment>
            <div style={{ marginTop: "50px" }}>
              <HighchartsReact highcharts={Highcharts} options={getOptions()} />
            </div>
          </React.Fragment>
        ) : (
          <Loader />
        )}
      </div>
      {!isLoading && <div className="card-footer small text-muted">.</div>}
    </div>
  );
}

export default WaterSoftenerTotal;
