import React, { useState } from "react";
import AccountService from "../../services/AccountService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector, useDispatch } from "react-redux";
import ucfirst from "../../utilities/functions";
import LoadingButton from "../../components/Button/LoadingButton";
import Form from "react-bootstrap/Form";

function Profile() {
  const user = useSelector((state) => state.auth.user);
  const [data, setData] = useState({
    username: user.username,
  });
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const accountService = new AccountService();
  const dispatch = useDispatch();

  function handleSubmit(e) {
    e.preventDefault();
    setIsLoading(true);

    accountService
      .saveProfile(data)
      .then(() => {
        if (user.username !== data.username) {
          // TODO: update state.nav.activeNavGroup to prevent closing account menu
          dispatch({ type: "REFRESH_TOKEN", data: { dispatch: dispatch } });
        }
        setError(false);
        setIsLoading(false);
      })
      .catch((error) => {
        setError(error.response.data.error.message);
        setIsLoading(false);
      });
  }

  return (
    <div className="card mb-3">
      <div className="card-header">
        <FontAwesomeIcon icon="cog" /> Profiel
      </div>
      <div className={`card-body` + (isLoading ? " loading" : "")}>
        <h5 style={{ textAlign: "center" }}>Profiel</h5>
        <br />

        <React.Fragment>
          {error && (
            <div className="alert alert-danger" role="alert">
              Fout opgetreden: "{error}"
            </div>
          )}

          {error === false && (
            <div className="alert alert-success" role="alert">
              Succesvol opgeslagen
            </div>
          )}

          <form method="POST" onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="username">
              <Form.Label>Gebruikersnaam</Form.Label>
              <Form.Control
                type="text"
                className="form-control"
                required
                readOnly
                value={ucfirst(data.username)}
                onChange={(e) => {
                  setData({
                    ...data,
                    username: e.target.value,
                  });
                }}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="current_password">
              <Form.Label>Huidig wachtwoord</Form.Label>
              <Form.Control
                type="password"
                className="form-control"
                required
                onChange={(e) => {
                  setData({
                    ...data,
                    current_password: e.target.value,
                  });
                }}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="new_password">
              <Form.Label>Nieuw wachtwoord</Form.Label>
              <Form.Control
                type="password"
                className="form-control"
                required
                onChange={(e) => {
                  setData({
                    ...data,
                    new_password: e.target.value,
                  });
                }}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="confirm_password">
              <Form.Label>Bevestig nieuw wachtwoord</Form.Label>
              <Form.Control
                type="password"
                className="form-control"
                required
                onChange={(e) => {
                  setData({
                    ...data,
                    confirm_password: e.target.value,
                  });
                }}
              />
            </Form.Group>

            <LoadingButton titleLoading="Opslaan.." isLoading={isLoading}>
              Opslaan
            </LoadingButton>
          </form>
        </React.Fragment>
      </div>
    </div>
  );
}

export default Profile;
