import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import WaterSoftenerService from "../../services/WaterSoftenerService";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";
import "moment/locale/nl";
import ucfirst from "../../utilities/functions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loader from "../../components/Loader/Loader";

function WaterSoftenerDay(props) {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { day } = useParams();

  function loadData() {
    setIsLoading(true);

    const waterSoftenerService = new WaterSoftenerService();

    waterSoftenerService.getDay(day).then((response) => {
      setData(response.data);
      setIsLoading(false);
    });
  }

  function getOptions() {
    return {
      chart: {
        type: "line",
        zoomType: "x",
      },
      title: {
        text: "Waterverbruik",
      },
      subtitle: {
        text: ucfirst(moment(data.date).format("dddd DD MMMM YYYY")),
      },
      xAxis: {
        type: "datetime",
        tickInterval: 3600 * 1000,
        min: data.start_time,
        max: data.end_time,
      },
      yAxis: {
        title: {
          text: "Liters",
        },
      },
      series: [
        {
          name: "Liters",
          color: "#2069a6",
          data: data.series,
        },
      ],
      plotOptions: {
        area: {
          marker: {
            enabled: false,
          },
        },
        line: {
          marker: {
            enabled: false,
          },
        },
      },
      credits: {
        enabled: false,
      },
    };
  }

  useEffect(() => {
    loadData();
  }, [day]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="card mb-3">
      <div className="card-header">
        <FontAwesomeIcon icon="chart-line" /> Waterverbruik - dag
      </div>
      <div className={`card-body` + (data && isLoading ? " loading" : "")}>
        {data ? (
          <React.Fragment>
            <Link
              to={`/waterontharder/dag/${data.prev_day}`}
              className="btn btn-outline-dark btn-sm"
            >
              « {ucfirst(moment(data.prev_day).format("dd DD MMMM YYYY"))}
            </Link>

            {moment(data.date).isBefore(moment().format("YYYY-MM-DD")) && (
              <Link
                to={`/waterontharder/dag/${data.next_day}`}
                className="btn btn-outline-dark btn-sm"
                style={{ float: "right" }}
              >
                {ucfirst(moment(data.next_day).format("dd DD MMMM YYYY"))} »
              </Link>
            )}

            <div style={{ marginTop: "50px" }}>
              <HighchartsReact highcharts={Highcharts} options={getOptions()} />

              <div style={{ overflowX: "auto" }}>
                <table className="graph-stats" style={{ width: "525px" }}>
                  <tbody>
                    <tr>
                      <td>Verbruik:</td>
                      <td>
                        {data.last_log !== null
                          ? data.last_log.liters_today
                          : 0}{" "}
                        liter
                      </td>
                    </tr>
                    <tr>
                      <td>Kosten:</td>
                      <td>
                        {data.prices.water_supply.toFixed(2)} € (
                        {data.prices.rates.water_m3 !== null
                          ? data.prices.rates.water_m3?.toFixed(2)
                          : 0}{" "}
                        / m3)
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </React.Fragment>
        ) : (
          <Loader />
        )}
      </div>
      {!isLoading && (
        <div className="card-footer small text-muted">
          Geupdatet om:{" "}
          {data && data.series.length > 0
            ? moment(data.series[data.series.length - 1][0]).format("HH:mm") +
              " uur"
            : "-"}
        </div>
      )}
    </div>
  );
}

export default WaterSoftenerDay;
