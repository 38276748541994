import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import ManagementService from "../../services/ManagementService";
import "moment/locale/nl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loader from "../../components/Loader/Loader";
import Table from "react-bootstrap/Table";
import moment from "moment";
import "moment/locale/nl";
import ucfirst from "../../utilities/functions";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import Button from "react-bootstrap/Button";

function ManagePricesDay() {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const managementService = new ManagementService();
  const { month } = useParams();
  const [activeGraph, setIsActiveGraph] = useState("month");

  function loadData() {
    setIsLoading(true);

    managementService.getPricesDay(month).then((response) => {
      setData(response.data);
      setIsLoading(false);
    });
  }

  function getOptions(period) {
    let title = "Dagprijzen";
    let subTitle = ucfirst(moment(data.month).format("MMMM YYYY"));
    let xAxis = {
      categories: data.days,
      plotLines: [
        {
          color: "red",
          width: 2,
          value:
            data.month === moment().format("YYYY-MM")
              ? parseInt(moment().format("D")) - 1
              : null,
          dashStyle: "ShortDot",
        },
      ],
    };
    let series = [
      {
        name: "Prijs incl. btw",
        color: "#2ccc00",
        data: data.series.month.price,
        zones: [
          {
            value: data.average_m3.month - data.average_m3.month * 0.02,
            color: "#2ccc00",
          },
          {
            value: data.average_m3.month + data.average_m3.month * 0.02,
            color: "grey",
          },
          {
            color: "#ff9900",
          },
        ],
      },
      {
        type: "line",
        name: "Gemiddelde",
        color: "black",
        dashStyle: "LongDash",
        data: data.series.month.avg,
        lineWidth: 1,
        cursor: "pointer",
        marker: {
          enabled: false,
        },
      },
    ];

    if (period === "year") {
      title = "Maandprijzen";
      subTitle = ucfirst(moment(data.month).format("YYYY"));
      xAxis = {
        categories: [
          "Jan",
          "Feb",
          "Mrt",
          "Apr",
          "Mei",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Okt",
          "Nov",
          "Dec",
        ],
      };
      series[0].data = data.series.year.price;
      series[0].zones[0].value =
        data.average_m3.year - data.average_m3.year * 0.1;
      series[0].zones[1].value =
        data.average_m3.year + data.average_m3.year * 0.1;
      series[1].data = data.series.year.avg;
    }

    return {
      chart: {
        type: "line",
        zoomType: "x",
      },
      title: {
        text: title,
      },
      subtitle: {
        text: subTitle,
      },
      tooltip: {
        shared: true,
      },
      xAxis: xAxis,
      yAxis: [
        {
          title: {
            text: "Euro",
          },
        },
      ],
      series: series,
      plotOptions: {
        series: {
          marker: {
            enabled: false,
          },
        },
      },
      credits: {
        enabled: false,
      },
    };
  }

  useEffect(() => {
    loadData();
  }, [month]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="card mb-3">
      <div className="card-header">
        <FontAwesomeIcon icon="table" /> Prijzen per dag
      </div>
      <div className={`card-body` + (data && isLoading ? " loading" : "")}>
        {data ? (
          <React.Fragment>
            <Link
              to={`/beheer/prijzen/dag/${data.prev_month}`}
              className="btn btn-outline-dark btn-sm"
            >
              « {ucfirst(moment(data.prev_month).format("MMM YYYY"))}
            </Link>

            {moment(data.month).isSameOrBefore(moment().format("YYYY-MM")) && (
              <Link
                to={`/beheer/prijzen/dag/${data.next_month}`}
                className="btn btn-outline-dark btn-sm"
                style={{ float: "right" }}
              >
                {ucfirst(moment(data.next_month).format("MMM YYYY"))} »
              </Link>
            )}

            <h5 className="mt-4 mb-4" style={{ textAlign: "center" }}>
              {ucfirst(moment(data.month).format("MMMM YYYY"))}
            </h5>

            <div className="mt-3">
              <Button
                className="btn btn-secondary btn-sm me-2"
                onClick={(e) => {
                  setIsActiveGraph("month");
                }}
              >
                Maand
              </Button>
              <Button
                className="btn btn-secondary btn-sm me-2"
                onClick={(e) => {
                  setIsActiveGraph("year");
                }}
              >
                Jaar
              </Button>
            </div>

            <div className="mt-3 mb-4">
              {activeGraph === "month" && (
                <HighchartsReact
                  highcharts={Highcharts}
                  options={getOptions("month")}
                />
              )}
              {activeGraph === "year" && (
                <HighchartsReact
                  highcharts={Highcharts}
                  options={getOptions("year")}
                />
              )}
            </div>

            <Table responsive style={{ minWidth: "1400px" }}>
              <thead>
                <tr style={{ fontWeight: "bold" }}>
                  <th scope="col" style={{ minWidth: "175px" }}>
                    Datum & tijd
                  </th>
                  <th scope="col" style={{ minWidth: "200px" }}>
                    m3 incl. btw ({data.btw_type}%)
                  </th>
                  <th scope="col" style={{ minWidth: "200px" }}>
                    m3 excl. btw
                  </th>
                  <th scope="col" style={{ minWidth: "200px" }}>
                    m3 inkoop
                  </th>
                  <th scope="col" style={{ minWidth: "200px" }}>
                    Energiebelasting
                  </th>
                  <th scope="col" style={{ minWidth: "250px" }}>
                    Transport + handelskosten
                  </th>
                  <th scope="col"></th>
                </tr>
              </thead>

              <tbody>
                {data.prices.map((price, index) => {
                  return (
                    <tr key={index}>
                      <td>{price.datetime}</td>
                      {data.btw_type === 9 && <td>{price.m3_btw_9}</td>}
                      {data.btw_type === 21 && <td>{price.m3_btw_21}</td>}
                      <td>{price.m3_ex_btw}</td>
                      <td>{price.m3}</td>
                      <td>{price.tax}</td>
                      <td>{price.transport + price.handling_fee}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </React.Fragment>
        ) : (
          <Loader />
        )}
      </div>
    </div>
  );
}

export default ManagePricesDay;
