import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import SolarPanelsService from "../../services/SolarPanelsService";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";
import "moment/locale/nl";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loader from "../../components/Loader/Loader";

function SolarPanelsYear(props) {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const { year } = useParams();

  function loadData() {
    setIsLoading(true);

    const solarPanelsService = new SolarPanelsService();

    solarPanelsService.getYear(year).then((response) => {
      setData(response.data);
      setIsLoading(false);
    });
  }

  function getOptions() {
    return {
      chart: {
        type: "column",
      },
      title: {
        text: "Opwekking",
      },
      subtitle: {
        text: data.year,
      },
      tooltip: {
        shared: true,
      },
      xAxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "Mei",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Okt",
          "Nov",
          "Dec",
        ],
        // crosshair: true
      },
      yAxis: {
        min: 0,
        title: {
          text: "kWh",
        },
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
        },
      },
      series: [
        {
          name: "kWh",
          color: "#f4cb42",
          data: data.series.kwh,
          cursor: "pointer",
          point: {
            events: {
              click: (e) => {
                const month = `${data.year}-${(e.point.index + 1)
                  .toString()
                  .padStart(2, "0")}`;

                navigate("/zonnepanelen/maand/" + month);
              },
            },
          },
        },
        {
          type: "line",
          name: "Verwachting",
          color: "#56844b",
          data: data.series.prediction,
          cursor: "pointer",
          marker: {
            enabled: false,
          },
        },
      ],
      credits: {
        enabled: false,
      },
    };
  }

  useEffect(() => {
    loadData(year);
  }, [year]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="card mb-3">
      <div className="card-header">
        <FontAwesomeIcon icon="chart-bar" /> Jaaropbrengst
      </div>
      <div className={`card-body` + (data && isLoading ? " loading" : "")}>
        {data ? (
          <React.Fragment>
            <Link
              to={`/zonnepanelen/jaar/${data.year - 1}`}
              className="btn btn-outline-dark btn-sm"
            >
              « {data.year - 1}
            </Link>

            {data.year + 1 <= parseInt(moment().format("YYYY")) && (
              <Link
                to={`/zonnepanelen/jaar/${data.year + 1}`}
                className="btn btn-outline-dark btn-sm"
                style={{ float: "right" }}
              >
                {data.year + 1} »
              </Link>
            )}

            <div style={{ marginTop: "50px" }}>
              <HighchartsReact highcharts={Highcharts} options={getOptions()} />

              <div style={{ overflowX: "auto" }}>
                <table className="graph-stats" style={{ width: "525px" }}>
                  <tbody>
                    <tr>
                      <td>Jaar kWh:</td>
                      <td>{data.year_total.toFixed(2)}</td>
                    </tr>
                    <tr>
                      <td>Prognose:</td>
                      <td>
                        {data.prognosis.toFixed(2)} % / {data.prediction_total}{" "}
                        kWh
                      </td>
                    </tr>
                    <tr>
                      <td>Werkelijk:</td>
                      <td>{data.actual.toFixed(2)} %</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </React.Fragment>
        ) : (
          <Loader />
        )}
      </div>
      {!isLoading && <div className="card-footer small text-muted">.</div>}
    </div>
  );
}

export default SolarPanelsYear;
