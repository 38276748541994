import React from "react";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch } from "react-redux";

function NavItem({ name, icon, route }) {
  const location = useLocation();
  const dispatch = useDispatch();
  const { pathname } = location;

  function handleSidebar() {
    dispatch({
      type: "TOGGLE_SIDEBAR",
    });
  }

  return (
    <li
      className={`nav-item ${pathname === route.prefix ? "active" : ""}`}
      title={name}
    >
      <Link className="nav-link" to={route.to} onClick={handleSidebar}>
        <FontAwesomeIcon icon={icon} />{" "}
        <span className="nav-link-text">{name}</span>
      </Link>
    </li>
  );
}

export default NavItem;
