import React, { useEffect, useState } from "react";
import LogService from "../../services/LogService";
import { Link, useParams, useNavigate } from "react-router-dom";
import moment from "moment";
import "moment/locale/nl";
import ucfirst from "../../utilities/functions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loader from "../../components/Loader/Loader";
import Table from "react-bootstrap/Table";
import DropdownButton from "../../components/Button/DropdownButton";

function LogSmartMeter() {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { day } = useParams();
  const logService = new LogService();
  let navigate = useNavigate();

  function loadData() {
    setIsLoading(true);

    logService.getSmartMeterLogs(day).then((response) => {
      setData(response.data);
      setIsLoading(false);
    });
  }

  function editLog(props) {
    navigate(`/log/slimme-meter/${props.datetime}/wijzigen`);
  }

  function deleteLog(props) {
    if (
      window.confirm("Weet je zeker dat je deze log regel wilt verwijderen?")
    ) {
      logService
        .deleteSmartMeterLog({
          datetime: props.datetime,
        })
        .then(() => {
          loadData();
        });
    }
  }

  useEffect(() => {
    loadData();
  }, [day]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="card mb-3">
      <div className="card-header">
        <FontAwesomeIcon icon="table" /> Slimme meter
      </div>
      <div className={`card-body` + (data && isLoading ? " loading" : "")}>
        {data ? (
          <React.Fragment>
            <Link
              to={`/log/slimme-meter/${data.prev_day}`}
              className="btn btn-outline-dark btn-sm"
            >
              « {ucfirst(moment(data.prev_day).format("dd DD MMM YYYY"))}
            </Link>

            {moment(data.date).isBefore(moment().format("YYYY-MM-DD")) && (
              <Link
                to={`/log/slimme-meter/${data.next_day}`}
                className="btn btn-outline-dark btn-sm"
                style={{ float: "right" }}
              >
                {ucfirst(moment(data.next_day).format("dd DD MMM YYYY"))} »
              </Link>
            )}

            <h5 className="mt-4 mb-4" style={{ textAlign: "center" }}>
              {ucfirst(moment(data.date).format("dddd DD MMMM YYYY"))}
            </h5>

            <Link
              to={`/log/slimme-meter/${data.date}/toevoegen`}
              className="btn btn-secondary mb-2"
            >
              Toevoegen
            </Link>

            <Table
              responsive
              style={{ minWidth: "2500px", minHeight: "200px" }}
            >
              <thead>
                <tr style={{ fontWeight: "bold" }}>
                  <th scope="col" style={{ minWidth: "175px" }}>
                    Datum & tijd
                  </th>
                  <th scope="col">Teller levering dal (kWh)</th>
                  <th scope="col">Teller levering normaal (kWh)</th>
                  <th scope="col">Teller teruglevering dal (kWh)</th>
                  <th scope="col">Teller teruglevering normaal (kWh)</th>
                  {data.data.length === 0 ||
                  data.data[data.data.length - 1].gas_m3 !== null ? (
                    <th scope="col">Teller levering gas (m3)</th>
                  ) : null}
                  <th scope="col">Actueel verbruik (kW)</th>
                  <th scope="col">Actuele teruglevering (kW)</th>
                  <th scope="col">Tarief</th>
                  <th scope="col">Actueel L1 (kW)</th>
                  <th scope="col">Actueel L2 (kW)</th>
                  <th scope="col">Actueel L3 (kW)</th>
                  <th scope="col"></th>
                </tr>
              </thead>

              <tbody>
                {data.data.map((log, index) => {
                  return (
                    <tr key={index}>
                      <td>{log.datetime}</td>
                      <td>{log.supply_low_kwh}</td>
                      <td>{log.supply_high_kwh}</td>
                      <td>{log.return_low_kwh}</td>
                      <td>{log.return_high_kwh}</td>
                      {data.data.length === 0 ||
                      data.data[data.data.length - 1].gas_m3 !== null ? (
                        <td>{log.gas_m3}</td>
                      ) : null}
                      <td>{log.consumption_current_kw}</td>
                      <td>{log.return_current_kw}</td>
                      <td>{log.rate === 2 ? "Normaal" : "Dal"}</td>
                      <td>
                        {log.l1_return_kw > 0
                          ? "-" + log.l1_return_kw
                          : log.l1_consumption_kw}{" "}
                        ({log.l1_current_a} A)
                      </td>
                      <td>
                        {log.l2_return_kw > 0
                          ? "-" + log.l2_return_kw
                          : log.l2_consumption_kw}{" "}
                        ({log.l2_current_a} A)
                      </td>
                      <td>
                        {log.l3_return_kw > 0
                          ? "-" + log.l3_return_kw
                          : log.l3_consumption_kw}{" "}
                        ({log.l3_current_a} A)
                      </td>
                      <td>
                        <DropdownButton
                          size="sm"
                          title="Acties"
                          items={[
                            {
                              title: "Wijzigen",
                              onClick: editLog,
                              onClickProps: { datetime: log.datetime },
                            },
                            {
                              title: "Verwijderen",
                              onClick: deleteLog,
                              onClickProps: { datetime: log.datetime },
                            },
                          ]}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </React.Fragment>
        ) : (
          <Loader />
        )}
      </div>
    </div>
  );
}

export default LogSmartMeter;
