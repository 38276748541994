import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./views/App";
import { Provider } from "react-redux";
import store from "./state/store";
import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";

import "./styles/index.css";
import "./styles/sb-admin/sb-admin.css";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap";

if (
  process.env.REACT_APP_SENTRY_DSN !== undefined &&
  process.env.REACT_APP_SENTRY_DSN !== ""
) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>,
  document.getElementById("root")
);
