import { post } from "../lib/http";

class AuthenticationService {
  login(username, password) {
    return post("/authorization/token", {
      username,
      password,
    });
  }

  refreshToken(refreshToken) {
    return post("/authorization/refresh", {
      refresh_token: refreshToken,
    });
  }
}

export default AuthenticationService;
