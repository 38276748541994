import { get, post, put, del } from "../lib/http";

class ManagementService {
  getPrice(date) {
    return get(`/beheer/prijzen/maand/${date}`);
  }

  getPrices() {
    return get("/beheer/prijzen/maand");
  }

  getPricesHour(date, cheapestHours) {
    return get(`/beheer/prijzen/uur/${date}?cheapest_hours=${cheapestHours}`);
  }

  getPricesDay(month) {
    return get(`/beheer/prijzen/dag/${month}`);
  }

  getPredictions() {
    return get("/beheer/verwachtingen");
  }

  getPeriodAmount(data) {
    return post("/beheer/termijnbedrag", data);
  }

  addPrice(data) {
    return post("/beheer/prijzen/maand", data);
  }

  editPrice(data, date) {
    return put(`/beheer/prijzen/maand/${date}`, data);
  }

  deletePrice(date) {
    return del(`/beheer/prijzen/maand/${date}`);
  }

  savePredictions(data) {
    return put("/beheer/verwachtingen", data);
  }
}

export default ManagementService;
