import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LogService from "../../services/LogService";
import { useParams } from "react-router-dom";
import LogSolarPanelsLogForm from "../../components/Form/SolarPanelsLogForm";

function LogSolarPanelsAdd() {
  const { day } = useParams();
  const [log, setLog] = useState({
    date: day,
  });
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  function handleSubmit(e) {
    e.preventDefault();
    setIsLoading(true);

    delete log.date;

    const logService = new LogService();

    logService
      .postSolarPanelsLog(log)
      .then(() => {
        setError(false);
        setIsLoading(false);
      })
      .catch((error) => {
        setError(error.response.data.error.message);
        setIsLoading(false);
      });
  }

  return (
    <div className="card mb-3">
      <div className="card-header">
        <FontAwesomeIcon icon="cog" /> Zonnepanelen - toevoegen
      </div>
      <div className={`card-body` + (isLoading ? " loading" : "")}>
        <h5 style={{ textAlign: "center" }}>Zonnepanelen - toevoegen</h5>
        <br />

        <React.Fragment>
          {error && (
            <div className="alert alert-danger" role="alert">
              Fout opgetreden: "{error}"
            </div>
          )}

          {error === false && (
            <div className="alert alert-success" role="alert">
              Succesvol opgeslagen
            </div>
          )}

          <LogSolarPanelsLogForm
            log={log}
            setLog={setLog}
            isLoading={isLoading}
            handleSubmit={handleSubmit}
          />
        </React.Fragment>
      </div>
    </div>
  );
}

export default LogSolarPanelsAdd;
