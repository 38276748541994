import { get, post, put, del } from "../lib/http";

class LogService {
  getSmartMeterLogs(date) {
    return get(`/log/slimme-meter/${date}`);
  }

  getSmartMeterLog(datetime) {
    return get(`/log/slimme-meter/datum-tijd/${datetime}`);
  }

  postSmartMeterLog(data) {
    return post(`/log/slimme-meter`, data);
  }

  editSmartMeterLog(data, datetime) {
    return put(`/log/slimme-meter/${datetime}`, data);
  }

  deleteSmartMeterLog(data) {
    return del(`/log/slimme-meter`, data);
  }

  getSolarPanelsLogs(date) {
    return get(`/log/zonnepanelen/${date}`);
  }

  postSolarPanelsLog(data) {
    return post(`/log/zonnepanelen`, data);
  }

  deleteSolarPanelsLog(data) {
    return del(`/log/zonnepanelen`, data);
  }

  getTemperatureLogs(date) {
    return get(`/log/temperatuur/${date}`);
  }

  deleteTemperatureLog(data) {
    return del(`/log/temperatuur`, data);
  }

  getTadoLogs(date, room) {
    return get(`/log/tado/${room}/${date}`);
  }

  deleteTadoLog(data) {
    return del(`/log/tado`, data);
  }

  getWaterSoftenerLogs(date) {
    return get(`/log/waterontharder/${date}`);
  }

  deleteWaterSoftenerLog(data) {
    return del(`/log/waterontharder`, data);
  }
}

export default LogService;
