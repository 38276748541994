import React, { useEffect, useState } from "react";
import SolarPanelsService from "../../services/SolarPanelsService";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loader from "../../components/Loader/Loader";

function SolarPanelsTotal() {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  function loadData() {
    setIsLoading(true);

    const solarPanelsService = new SolarPanelsService();

    solarPanelsService.getTotal().then((response) => {
      setData(response.data);
      setIsLoading(false);
    });
  }

  function getOptions() {
    return {
      chart: {
        type: "column",
      },
      title: {
        text: "Opwekking",
      },
      subtitle: {
        text: "Totaaloverzicht",
      },
      tooltip: {
        shared: true,
      },
      xAxis: {
        categories: data.years,
      },
      yAxis: {
        min: 0,
        title: {
          text: "kWh",
        },
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
        },
      },
      series: [
        {
          name: "kWh",
          color: "#f4cb42",
          data: data.series.kwh,
          cursor: "pointer",
          point: {
            events: {
              click: (e) => {
                navigate("/zonnepanelen/jaar/" + e.point.category);
              },
            },
          },
        },
        {
          type: "line",
          name: "Verwachting",
          color: "#56844b",
          data: data.series.prediction,
          cursor: "pointer",
          marker: {
            enabled: false,
          },
        },
      ],
      credits: {
        enabled: false,
      },
    };
  }

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div className="card mb-3">
      <div className="card-header">
        <FontAwesomeIcon icon="chart-bar" /> Totaaloverzicht
      </div>
      <div className={`card-body` + (data && isLoading ? " loading" : "")}>
        {data ? (
          <React.Fragment>
            <div style={{ marginTop: "50px" }}>
              <HighchartsReact highcharts={Highcharts} options={getOptions()} />
            </div>
          </React.Fragment>
        ) : (
          <Loader />
        )}
      </div>
      {!isLoading && <div className="card-footer small text-muted">.</div>}
    </div>
  );
}

export default SolarPanelsTotal;
