import React from "react";
import { useNavigate } from "react-router-dom";
import LoadingButton from "../Button/LoadingButton";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

function LogSmartMeterForm({ log, setLog, isLoading, handleSubmit }) {
  let navigate = useNavigate();

  return (
    <form method="POST" onSubmit={handleSubmit}>
      <Form.Group className="mb-3" controlId="date">
        <Form.Label>Datum</Form.Label>
        <Form.Control
          type="date"
          value={log.datetime.split(" ")[0]}
          onChange={(e) => {
            const tempDatetime = log.datetime.split(" ");
            setLog({
              ...log,
              date: `${e.target.value} ${tempDatetime[1]}`,
            });
          }}
        />
      </Form.Group>

      <Form.Group className="mb-3" controlId="time">
        <Form.Label>Tijd</Form.Label>
        <Form.Control
          type="time"
          className="form-control"
          required
          value={log.datetime.split(" ")[1]}
          onChange={(e) => {
            const tempDatetime = log.datetime.split(" ");
            setLog({
              ...log,
              datetime: `${tempDatetime[0]} ${e.target.value}`,
            });
          }}
        />
      </Form.Group>

      <Form.Group className="mb-3" controlId="supply_low_kwh">
        <Form.Label>Teller levering dal (kWh)</Form.Label>
        <Form.Control
          type="number"
          className="form-control"
          step="0.001"
          required
          value={log.supply_low_kwh !== undefined ? log.supply_low_kwh : ""}
          onChange={(e) => {
            setLog({ ...log, supply_low_kwh: e.target.value });
          }}
        />
      </Form.Group>

      <Form.Group className="mb-3" controlId="supply_high_kwh">
        <Form.Label>Teller levering normaal (kWh)</Form.Label>
        <Form.Control
          type="number"
          className="form-control"
          step="0.001"
          required
          value={log.supply_high_kwh !== undefined ? log.supply_high_kwh : ""}
          onChange={(e) => {
            setLog({ ...log, supply_high_kwh: e.target.value });
          }}
        />
      </Form.Group>

      <Form.Group className="mb-3" controlId="return_low_kwh">
        <Form.Label>Teller teruglevering dal (kWh)</Form.Label>
        <Form.Control
          type="number"
          className="form-control"
          step="0.001"
          required
          value={log.return_low_kwh !== undefined ? log.return_low_kwh : ""}
          onChange={(e) => {
            setLog({ ...log, return_low_kwh: e.target.value });
          }}
        />
      </Form.Group>

      <Form.Group className="mb-3" controlId="return_high_kwh">
        <Form.Label>Teller teruglevering normaal (kWh)</Form.Label>
        <Form.Control
          type="number"
          className="form-control"
          step="0.001"
          required
          value={log.return_high_kwh !== undefined ? log.return_high_kwh : ""}
          onChange={(e) => {
            setLog({ ...log, return_high_kwh: e.target.value });
          }}
        />
      </Form.Group>

      <Form.Group className="mb-3" controlId="gas_m3">
        <Form.Label>Teller levering gas (m3)</Form.Label>
        <Form.Control
          type="number"
          className="form-control"
          step="0.001"
          value={log.gas_m3 !== undefined ? log.gas_m3 : ""}
          onChange={(e) => {
            setLog({
              ...log,
              gas_m3: e.target.value > 0 ? e.target.value : undefined,
            });
          }}
        />
      </Form.Group>

      <Form.Group className="mb-3" controlId="consumption_current_kw">
        <Form.Label>Actueel verbruik (kW)</Form.Label>
        <Form.Control
          type="number"
          className="form-control"
          step="0.001"
          required
          value={log.consumption_current_kw}
          onChange={(e) => {
            setLog({ ...log, consumption_current_kw: e.target.value });
          }}
        />
      </Form.Group>

      <Form.Group className="mb-3" controlId="return_current_kw">
        <Form.Label>Actuele teruglevering (kW)</Form.Label>
        <Form.Control
          type="number"
          className="form-control"
          step="0.001"
          required
          value={log.return_current_kw}
          onChange={(e) => {
            setLog({ ...log, return_current_kw: e.target.value });
          }}
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Tarief</Form.Label>
        <Form.Check
          checked={log.rate === 1}
          label="Dal"
          type="radio"
          onChange={() => {
            setLog({ ...log, rate: 1 });
          }}
          id="rate_low"
        />
        <Form.Check
          checked={log.rate === 2}
          label="Normaal"
          type="radio"
          onChange={() => {
            setLog({ ...log, rate: 2 });
          }}
          id="rate_high"
        />
      </Form.Group>

      <Button
        variant="outline-dark"
        className="me-3"
        onClick={(e) => {
          navigate(-1);
        }}
      >
        Terug
      </Button>

      <LoadingButton titleLoading="Opslaan.." isLoading={isLoading}>
        Opslaan
      </LoadingButton>
    </form>
  );
}

export default LogSmartMeterForm;
