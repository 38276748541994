import React from "react";

function SmartMeterElektricityStats({ data }) {
  return (
    <div style={{ overflowX: "auto" }}>
      <table className="graph-stats" style={{ width: "500px" }}>
        <tbody>
          <tr>
            <td>Verbruik:</td>
            <td style={{ textAlign: "right" }}>
              {data.totals.consumption.toFixed(2)} kWh
            </td>
            <td />
            <td />
          </tr>
          <tr>
            <td>Levering:</td>
            <td style={{ textAlign: "right" }}>
              {data.totals.supply.toFixed(2)} kWh
            </td>
            <td style={{ textAlign: "right" }}>
              {data.totals.costs.supply.toFixed(2)} €
            </td>
            <td style={{ textAlign: "left", paddingLeft: "10px" }}>
              (
              {data.totals.supply > 0
                ? (data.totals.costs.supply / data.totals.supply).toFixed(2)
                : 0}{" "}
              / kWh)
            </td>
          </tr>
          <tr>
            <td>Teruglevering:</td>
            <td style={{ textAlign: "right" }}>
              {data.totals.return.toFixed(2)} kWh
            </td>
            <td style={{ textAlign: "right" }}>
              {data.totals.costs.return.toFixed(2)} €
            </td>
            <td style={{ textAlign: "left", paddingLeft: "10px" }}>
              (
              {data.totals.return > 0
                ? (data.totals.costs.return / data.totals.return).toFixed(2)
                : 0}{" "}
              / kWh)
            </td>
          </tr>
          <tr>
            <td>Opwekking:</td>
            <td style={{ textAlign: "right" }}>
              {data.totals.generation.toFixed(2)} kWh
            </td>
            <td />
            <td />
          </tr>
          <tr>
            <td>Kosten:</td>
            <td style={{ textAlign: "right" }}>
              {(
                data.totals.costs.supply.toFixed(2) -
                data.totals.costs.return.toFixed(2)
              ).toFixed(2)}{" "}
              €
            </td>
            <td />
            <td />
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>Levering normaal:</td>
            <td style={{ textAlign: "right" }}>
              {data.totals.supply_high.toFixed(2)} kWh
            </td>
            <td style={{ textAlign: "right" }}>
              {data.totals.costs.supply_high.toFixed(2)} €
            </td>
            <td style={{ textAlign: "left", paddingLeft: "10px" }}>
              (
              {data.totals.supply_high > 0
                ? (
                    data.totals.costs.supply_high / data.totals.supply_high
                  ).toFixed(2)
                : 0}{" "}
              / kWh)
            </td>
          </tr>
          <tr>
            <td>Levering dal:</td>
            <td style={{ textAlign: "right" }}>
              {data.totals.supply_low.toFixed(2)} kWh
            </td>
            <td style={{ textAlign: "right" }}>
              {data.totals.costs.supply_low.toFixed(2)} €
            </td>
            <td style={{ textAlign: "left", paddingLeft: "10px" }}>
              (
              {data.totals.supply_low > 0
                ? (
                    data.totals.costs.supply_low / data.totals.supply_low
                  ).toFixed(2)
                : 0}{" "}
              / kWh)
            </td>
          </tr>
          <tr>
            <td>Teruglevering normaal:</td>
            <td style={{ textAlign: "right" }}>
              {data.totals.return_high.toFixed(2)} kWh
            </td>
            <td style={{ textAlign: "right" }}>
              {data.totals.costs.return_high.toFixed(2)} €
            </td>
            <td style={{ textAlign: "left", paddingLeft: "10px" }}>
              (
              {data.totals.return_high > 0
                ? (
                    data.totals.costs.return_high / data.totals.return_high
                  ).toFixed(2)
                : 0}{" "}
              / kWh)
            </td>
          </tr>
          <tr>
            <td>Teruglevering dal:</td>
            <td style={{ textAlign: "right" }}>
              {data.totals.return_low.toFixed(2)} kWh
            </td>
            <td style={{ textAlign: "right" }}>
              {data.totals.costs.return_low.toFixed(2)} €
            </td>
            <td style={{ textAlign: "left", paddingLeft: "10px" }}>
              (
              {data.totals.return_low > 0
                ? (
                    data.totals.costs.return_low / data.totals.return_low
                  ).toFixed(2)
                : 0}{" "}
              / kWh)
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default SmartMeterElektricityStats;
